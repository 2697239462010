import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';

export default function Loading() {
    return (
        <Modal open>
            <Box
                sx={{
                    position:'relative',
                    textAlign: 'center',
                    top:'50%',
                    transform:'translateY(-50%)',
                    p: 8,
                    outline:'none'
                }}
            >
                <LinearProgress/>
            </Box>
        </Modal>
    );
}