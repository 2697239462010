export default class Account {
    constructor(rawAccount, accountId, basePath) {
        this._ = rawAccount;
        this.id = accountId;
        this.path = `${basePath}/${accountId}`;
        this.card = rawAccount.card;
        this.cash = rawAccount.cash;
        this.category = rawAccount.category;
        this.currency = rawAccount.currency;
        this.date = rawAccount.date;
        this.title = rawAccount.title;
        this.detail = rawAccount.detail ?? '';
        this.identifier = rawAccount.identifier;
        this.writer = rawAccount.writer;
        this.timestamp = rawAccount.timestamp;
        this.createAt = rawAccount.createAt;
        this.updateAt = rawAccount.updateAt;
    }

    get memo() {
        return this.detail;
    }

    get time() {
        return new Date(this.timestamp ?? this.date);
    }
}

