import { createContext, PropsWithChildren, useContext, useReducer } from 'react';
const initialState = {
    reply: {
        id: '',
        text: '',
        sender: {},
    },
};
const ChatContext = createContext<any>(null);

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SET_REPLY': {
            return {
                ...state,
                reply: action.payload,
            };
        }
        case 'CLEAR_REPLY': {
            return {
                ...state,
                reply: {
                    id: '',
                    text: '',
                },
            };
        }
        default: {
            console.log('Unhandle action type');
        }
    }
};

export const ChatContextProvider = ({ children }: PropsWithChildren) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <ChatContext.Provider value={{ state, dispatch }}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => useContext(ChatContext);
