import React, {useContext, useEffect, useRef, useState} from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import SendIcon from '@mui/icons-material/Send';
import {Box, IconButton, SxProps, TextField} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import ImageUploadSwiper from './ImageUploadSwiper';
import {sendMessage} from '../../../controller/chat';
import {GuideContext} from '../../../controller/context/GuideContext';
import {IChatMeta, IParticipant} from '../../../types/chat';
import {useChatContext} from '../../../context/ChatContext';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {read} from "../../../controller/firebase";
import {getDocData} from "../../../controller/chat/firebase";
import Team from "../../../model/Team";

interface IProps {
    sx?: SxProps;
    participants: IParticipant[];
}

interface IForm {
    text: string;
}

function capitalFirst(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function splitAndCapitalize(name: string): string {
    return name.split(' ').filter(s => !!s).map(capitalFirst).join(' ');
}

function consistPickupMessage(team: Team | null, product: any): string {
    if (!team || !product) return '';
    return team.pickupPlaces.map((place) => {
        const pickupElement = product.chat?.pickup?.[place.key];
        if (!pickupElement || !pickupElement.use || !pickupElement.en)
            return ({
                order: -99,
                text: ''
            }) // 없는경우 표시 제외
        const pickupTitle = `⭐️${pickupElement?.en ? `${[pickupElement.en, pickupElement.cn ?? ''].filter(t => !!t).join('\n')}` : place.key}`;
        const list = place.reservations.map((reservation: { clientName: string }) => '-' + splitAndCapitalize(reservation.clientName.replace(/\(.+\)/gi, ''))).join('\n');
        return ({
            order: pickupElement?.order,
            text: `${pickupTitle}\n${list}`
        });
    }).sort((a: any, b: any) => a.order < b.order ? -1 : 0)
        .map((p) => p.text)
        .filter((s) => !!s) // 텍스트가 없는 경우 표시 제외
        .join('\n\n')
}

export default function MessageForm({sx, participants}: IProps) {
    const [team, setTeam] = useState<Team | null>(null);
    const [product, setProduct] = useState<any>(null);
    const [mobile, setMobile] = useState(false);
    const [openSwiper, setOpenSwiper] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isActions, setIsActions] = useState<boolean>(false);
    const [messagePresets, setMessagePresets] = useState<any>({});
    const [action, setAction] = useState<string>('');
    const [text, setText] = useState<string>('');
    const messageFormRef = useRef<HTMLInputElement>(null);
    const {register, reset, watch, handleSubmit, setValue} = useForm<IForm>();

    const {state: chatState, dispatch: chatDispatch} = useChatContext();
    const {chatId} = useParams() as { chatId: string };
    const {guide} = useContext(GuideContext) as any;


    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        setMobile(mobile);

    }, []);

    useEffect(() => {
        if (product?.area) {
            read('/messages').then((messagesSnapshot) => {
                const messages: { [_id: string]: { key: string, id: string, messages: string[], area?: string } } = messagesSnapshot.val() ?? {};
                const messagePresets = Object.fromEntries(Object.values(messages)
                    .filter(({area}) => area === product?.area)
                    .map(({key, messages}) => ([key, messages])));
                if (!guide.outsource) {
                    setMessagePresets(messagePresets);
                }
            })
        }
    }, [product?.area, guide.level]);

    useEffect(() => {
        if (chatId) {
            (async () => {
                const {tour} = (await getDocData(['chats', chatId])) as any;
                if (tour) {
                    const {date, productId, team, teamId} = tour;
                    const refId = `/operation/${date}/tours/${productId}/teams/${team ?? teamId}`;
                    const teamVal = (await read(refId)).val();
                    if (teamVal) {
                        setTeam(new Team(teamVal, team ?? teamId, `/operation/${date}/tours/${productId}/teams/`));
                    } else {
                        setTeam(null);
                    }

                    const productRefId = `/product/${productId}`;
                    const productVal = (await read(productRefId)).val();
                    if (productVal) {
                        setProduct(productVal);
                    } else {
                        setProduct(null);
                    }
                }
            })().catch(console.error);
        }
    }, [chatId]);


    async function onSendMessage({text}: IForm) {
        // if (participants.length <= 1) {
        //     alert('채팅방에 참여자가 없습니다.');
        //     return;
        // }
        if (messageFormRef.current) messageFormRef.current.focus();
        if (isSending || text?.trim() === '') return;

        setIsSending(true);

        if (text.startsWith('/')) {
            const action = text.replace('/', '');
            const messagePreset = messagePresets[action];
            if (Array.isArray(messagePreset)) {
                (async () => {
                    for (let message of messagePreset) {
                        await sendMessage(chatId, participants, guide, message)
                    }
                })()
                    .catch((e) => {
                        console.error(e);
                        alert('메세지 전송에 에러가 발생했습니다.')
                    })
                    .finally(() => {
                        reset();
                        setIsSending(false);
                        setAction('');
                        chatDispatch({
                            type: 'CLEAR_REPLY',
                        });
                    })
                return;
            } else {
                if (action === 'pickup') {
                    if (team && product) {
                        const text = consistPickupMessage(team, product);
                        const message = {
                            text,
                            type: 'text'
                        }
                        sendMessage(chatId, participants, guide, message)
                            .catch((e) => {
                                console.error(e);
                                alert('메세지 전송에 에러가 발생했습니다.')
                            })
                            .finally(() => {
                                reset();
                                setIsSending(false);
                                setAction('');
                                chatDispatch({
                                    type: 'CLEAR_REPLY',
                                });
                            })
                    }
                    return;
                }
            }
        }


        const message = {
            type: chatState.reply.id ? 'reply' : 'text',
            text,
        };

        await sendMessage(chatId, participants, guide, message, chatState.reply.id ? chatState.reply : undefined);
        reset();
        setIsSending(false);
        setAction('');
        chatDispatch({
            type: 'CLEAR_REPLY',
        });
    }

    async function checkKeyDown(event: any) {
        if (!mobile && event.key === 'Enter' && event.nativeEvent.isComposing === false && !event.shiftKey) {
            event.preventDefault();
            const data = {
                text: event.target.value,
            };
            onSendMessage(data);
            return;
        }
    }

    const handleChange = (e: any) => {
        const currentValue = e.target.value ?? '';
        if (currentValue.startsWith('/')) {
            setAction(currentValue);
        } else {
            setAction('');
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSendMessage)} onChange={handleChange}>
                <Stack direction={"column"}>
                    {
                        action
                            ? (
                                <Stack direction={"column"} gap={1} sx={{backgroundColor: 'white'}}>
                                    {
                                        Object.keys(messagePresets)
                                            .concat(team ? ['pickup'] : [])
                                            .filter(key => key.startsWith(action.replace('/', '')))
                                            .slice(0, 4)
                                            .map((key: string) => (
                                                <Button fullWidth variant={'text'} onClick={() => {
                                                    setValue('text', `/${key}`)
                                                    setAction('');
                                                }}>{key}</Button>
                                            ))
                                    }
                                </Stack>
                            )
                            : null
                    }
                    <Box sx={sx}>
                        <AddPhotoAlternateIcon
                            sx={{
                                width: '42px',
                                height: '42px',
                                fill: '#bdbdbd',
                            }}
                            onClick={() => {
                                // if (participants.length <= 1) {
                                //     alert('채팅방에 참여자가 없습니다.');
                                //     return;
                                // }
                                setOpenSwiper(true);
                            }}
                        />

                        <Box
                            sx={{
                                maxHeight: '20vh',
                                overflowY: 'scroll',
                                '&-ms-overflow-style': 'none',
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                width: '100%',
                            }}
                        >
                            <TextField
                                inputRef={messageFormRef}
                                variant="standard"
                                multiline={true}
                                fullWidth
                                // placeholder={images.length > 0 ? "이미지" : "메시지를 입력하세요."}
                                // disabled={images.length > 0}
                                sx={{
                                    flex: 1,

                                    padding: '10px 20px',
                                    background: '#FAFAFA',
                                    borderRadius: '10px',
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                onKeyDown={checkKeyDown}
                                {...register('text')}
                            />
                        </Box>
                        <IconButton
                            type="submit"
                            style={{
                                borderRadius: '50%',
                                height: '48px',
                                width: '48px',
                                background: watch('text') ? '#3538bb' : '#e0e0e0',
                                transform: 'rotate(-40deg)',
                            }}
                            // disabled={participants.length <= 1}
                        >
                            <SendIcon
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                    fill: '#fff',
                                }}
                            />
                        </IconButton>
                    </Box>
                </Stack>
            </form>
            <ImageUploadSwiper
                anchor="bottom"
                open={openSwiper}
                onOpen={() => {
                    setOpenSwiper(true);
                }}
                setOpen={setOpenSwiper}
                onClose={() => setOpenSwiper(false)}
                participants={participants}
            />
        </>
    );
}
