import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {BrowserRouter, Route, Routes, redirect, Outlet, Navigate} from 'react-router-dom';



import {ThemeProvider} from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license'
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {useGuideContext, useGuideContextProvider} from './controller/context/GuideContext';
import {useDateContextProvider} from './controller/context/DateContext';

import LayoutRoot from './layout/Root';
import ErrorPage from './routes/ErrorPage';
import TourPage from './routes/TourPage';
import PaymentPage from './routes/PaymentPage';
import BalancePage from './routes/BalancePage';
import ChatPage from './routes/ChatPage/Home';
import BusPage from "./routes/BusPage";
import Box from '@mui/material/Box';
import './styles/global.css';
import {SnackbarProvider} from 'notistack';
import {DrawerContextPropvider, DrawerView} from './context/DrawerContext';
import Chat from './routes/ChatPage/Chat';
import Chats from './routes/ChatPage/Chats';
import {ChatContextProvider} from './context/ChatContext';

LicenseInfo.setLicenseKey(
    "317b392a3e4674c2f3007ab1cd66eb24Tz05MDE1OCxFPTE3NDcxMDQ5NjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
)

export default function App() {
    const GuideContextProvider = useGuideContextProvider();
    const DateContextProvider = useDateContextProvider();
    const chatNotificationClickHandler = useCallback(
        (event) => {
            if (event.data.action === 'chat-notification-clicked') {
                window.focus();
                window.location.href = event.data.url;
            }
        },
        [window],
    );

    useEffect(() => {
        if (window && window.navigator && navigator.serviceWorker)
            window.navigator.serviceWorker.addEventListener('message', chatNotificationClickHandler);
        return () => {
            if (window && window.navigator && navigator.serviceWorker)
                window.navigator.serviceWorker.removeEventListener('message', chatNotificationClickHandler);
        };
    }, [chatNotificationClickHandler]);

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline/>
                <GuideContextProvider>
                    <DateContextProvider>
                        <SnackbarProvider maxSnack={3}>
                            <DrawerContextPropvider>
                                <Router/>
                            </DrawerContextPropvider>
                        </SnackbarProvider>
                    </DateContextProvider>
                </GuideContextProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

function Router(props) {
    const {guide} = useGuideContext();
    return guide?.bus
        ? (
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<LayoutRoot/>} errerrorElement={ErrorPage}>
                        <Route path={'/'} element={<BusPage/>}/>
                        <Route path={'/payment'} element={<PaymentPage/>}/>
                        <Route path={'/balance'} element={<BalancePage/>}/>
                    </Route>
                    <Route path={'*'} element={<Navigate to={'/'}/>}></Route>
                </Routes>
            </BrowserRouter>
        )
        : guide?.promotion
            ? (
                <BrowserRouter>
                    <Routes>
                        <Route path={'*'} element={<LayoutRoot/>} errerrorElement={ErrorPage}>
                            <Route path={'payment'} element={<PaymentPage/>}/>
                            <Route
                                path={'chat'}
                                element={
                                    <Box>
                                        <Outlet/>
                                        <DrawerView/>
                                    </Box>
                                }
                            >
                                <Route index element={<ChatPage/>}/>
                                <Route path={'rooms'} element={<Chats/>}/>
                                <Route
                                    path={'rooms/:chatId'}
                                    element={
                                        <ChatContextProvider>
                                            <Chat/>
                                        </ChatContextProvider>
                                    }
                                />
                            </Route>
                            <Route path={'*'} element={<Navigate to={'/chat/rooms'}/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            )
            : (
                <BrowserRouter>
                    <Routes>
                        <Route path={'/'} element={<LayoutRoot/>} errerrorElement={ErrorPage}>
                            <Route path={'/'} element={<TourPage/>}/>
                            <Route path={'/payment'} element={<PaymentPage/>}/>
                            <Route path={'/balance'} element={<BalancePage/>}/>
                            <Route
                                path={'/chat'}
                                element={
                                    <Box>
                                        <Outlet/>
                                        <DrawerView/>
                                    </Box>
                                }
                            >
                                <Route index element={<ChatPage/>}/>
                                <Route path={'rooms'} element={<Chats/>}/>
                                <Route
                                    path={'rooms/:chatId'}
                                    element={
                                        <ChatContextProvider>
                                            <Chat/>
                                        </ChatContextProvider>
                                    }
                                />
                            </Route>
                            {/*<Route*/}
                            {/*    path={'/admin'}*/}
                            {/*    element={<AdminPage/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path={'/monitor'}*/}
                            {/*    element={<MonitorPage/>}*/}
                            {/*/>*/}
                        </Route>
                        <Route path={'/'} errerrorElement={ErrorPage}></Route>
                    </Routes>
                </BrowserRouter>
            )
}
