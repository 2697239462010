import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import AccountCard from './AccountCard';
import AccountList from './AccountList';

import {useGuideContext} from '../../controller/context/GuideContext';
import {useDateContext} from '../../controller/context/DateContext';


export default function PaymentPage() {


    return (
        <Box>
            <AccountCard/>
            <Divider/>
                <AccountList/>
        </Box>
    );
}
