import {Badge, Box, ButtonBase, Typography} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Unsubscribe} from 'firebase/auth';
import CloseIcon from '@mui/icons-material/Close';
import {grey, red} from '@mui/material/colors';
import {IChatMeta, ITour} from '../../../types/chat';
import {GuideContext} from '../../../controller/context/GuideContext';
import {
    countChatMessages,
    deleteChat,
    subscribeToLastMessage,
    subscribeToTeamOfChat,
    subscribeToUnreadMessages
} from '../../../controller/chat';
import CircularProgress from '@mui/material/CircularProgress';
import {callFunction, readRealtime} from "../../../controller/chat/firebase";
import {guid} from "@fullcalendar/core/internal";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import CircleIcon from '@mui/icons-material/Circle';

interface Props {
    chatMeta: IChatMeta;
    products: {[key: string]: any };
    date?:string,
    chatsState:{[key:string]:any}
    highlight?:boolean
}

export default function ChatRoom({chatMeta, products, date, chatsState, highlight}: Props) {
    const {guide} = useContext(GuideContext) as any;
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [count, setCount] = useState<number>(1); // 최초 X를 안보여줌
    const [latestMessage, setLatestMessage] = useState<any>(null);
    const [isBelong, setIsBelong] = useState<boolean>(true);



    const chatTour: ITour = chatMeta?.tour as ITour
    const product = chatTour ? products?.[chatTour.tourId || chatTour.productId] : undefined;

    const hasClient = Object.values(chatMeta.participants ?? {}).filter(p => p.type?.toLowerCase().startsWith('cl'));
    const participantNames = Object.values(chatMeta.participants ?? {})
        .filter(p => !!p.id)
        .filter((participant) => participant.id !== guide.id)
        .filter((p) => hasClient ? p.type.toLowerCase().includes('cl') : true)
        .map((participant) => participant.name);

    const navigate = useNavigate();


    const threeDaysAgo = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
    const chatDate = chatMeta.tour && (chatMeta.tour as ITour).date ? new Date((chatMeta.tour as ITour).date) : new Date(0)

    const hasNoMessage = count === 0;
    const notTeamChat = !chatMeta.tour || (!(chatMeta.tour as ITour).team && !(chatMeta.tour as ITour).teamId);
    const notUnderMyGuide = !isBelong;
    const isPast = chatDate < threeDaysAgo;
    const isDeletable = hasNoMessage || notTeamChat || notUnderMyGuide || isPast

    useEffect(() => {
        const unsubs: Unsubscribe[] = [];
        if (!chatMeta.id || !guide.id) return () => {
            if (unsubs.length > 0) {
                unsubs.forEach((unsub) => unsub());
            }
        }
        unsubs.push(
            subscribeToUnreadMessages(chatMeta.id, guide.id, (querySnapshot: any) => {
                setUnreadCount(querySnapshot.docs.length);
            }),
        );
        unsubs.push(
            subscribeToLastMessage(chatMeta.id, (querySnapshot) => {
                querySnapshot.forEach((doc: any) => {
                    if (doc.exists()) {
                        setLatestMessage(doc.data() as any);
                    }
                });
            }),
        );
        subscribeToTeamOfChat(chatMeta?.id, (dataSnapshot) => {
            const team = dataSnapshot.val();
            const guides = team?.guides ?? [];
            const isBelong = !!guides.find(({id}: { id: string }) => id === guide?.id);
            setIsBelong(isBelong);
        }).then(unsub => unsubs.push(unsub))

        countChatMessages(chatMeta.id).then((c) => {
            setCount(c);
        });

        return () => {
            if (unsubs.length > 0) {
                unsubs.forEach((unsub) => unsub());
            }
        };
    }, [chatMeta?.id, guide?.id]);

    async function handleDeleteChat(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDeleteLoading(true);
        await deleteChat(chatMeta.id, guide);
        setDeleteLoading(false);
    }

    return (
        <ButtonBase
            sx={{
                p: 2,
                display: 'flex',
                position: 'relative',
                alignItems: 'start',
                columnGap: 1.5,
                width: '100%',
                border: '1px solid #E9F4FF',
                maxHeight: '200px',
                overflow: 'hidden',
                borderRadius: '16px',
                transition: 'background .8s',
                background: deleteLoading ? red[100] :
                    highlight ?  '#f0faff' : '#fff',
            }}
            disabled={deleteLoading}
            onClick={() =>
                navigate(chatMeta.id, {
                    relative: 'path',
                    state: {
                        chatMeta,
                        ...(chatsState ?? {})
                    },
                })
            }
        >
            {
                isDeletable &&
                <ButtonBase
                    sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        color: deleteLoading ? grey[500] : red[500],
                    }}
                    onClick={handleDeleteChat}
                >
                    {deleteLoading && <CircularProgress sx={{mr: 1, mt: 0.5}} size={18} color="inherit"/>}
                    {!deleteLoading && <CloseIcon sx={{mr: 1}}/>}
                </ButtonBase>

            }
            <Box
                sx={{
                    flex: 1,
                    mt: 1,
                }}
            >
                <Typography
                    sx={{
                        textAlign: 'left',
                    }}
                >
                    [{chatTour?.date} {product?.name ?? chatTour?.tourId ?? chatTour?.productId ?? 'unknown'}]
                </Typography>
                <Typography
                    sx={{
                        fontSize: '18px',
                        color: 'var(--black)',
                        fontWeight: 700,
                        textAlign: 'left',
                    }}
                >
                    {participantNames.join(', ')}
                </Typography>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: unreadCount === 0 ? '#4f4f4f' : '#ff0060',
                            textAlign: 'left',
                        }}
                    >
                        {unreadCount > 0 ? `(${unreadCount}) ` : ''}
                        {latestMessage?.type === 'text' ||
                        latestMessage?.type === 'reply' ||
                        latestMessage?.type === 'exit' ||
                        latestMessage?.type === 'enter'
                            ? latestMessage?.text
                            : latestMessage?.type === 'image'
                                ? 'Image'
                                : ''}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'right',
                            fontSize: '12px',
                            color: unreadCount === 0 ? '#4f4f4f' : '#ff0060',
                        }}
                    >
                        {latestMessage?.date?.toDate().toLocaleTimeString().split(':').slice(0, 2).join(':')}
                    </Typography>
                </Box>
            </Box>
        </ButtonBase>
    );
}
