import {useEffect, useState} from 'react';
import {listen, read} from '../index';

export default function useFListen(on, path, format = f => f, defaultValue) {
    const [data, setData] = useState(defaultValue ?? format(null));
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        if (on) {
            // const intervalRead = ()=>{
            //     const handler = (snapshot) => {
            //         setData(format(snapshot.val()));
            //     };
            //     const errorHandler = (e) => {
            //         console.error(e);
            //     };
            //
            //     read(path)
            //         .then(handler)
            //         .catch(errorHandler)
            // };
            // const interval = setInterval(intervalRead,5000)

            const handler = (snapshot) => {
                setData(format(snapshot.val(), path, snapshot));
                setIsLoading(false);
            };
            const errorHandler = (e) => {
                setError(e);
            };
            const unsubscribe = listen(path, handler, errorHandler);
            return () => {
                unsubscribe();
                try{
                    // clearInterval(interval);
                }catch(e){
                    console.error(e);
                }
            };
        }else{
            const handler = (snapshot) => {
                setData(format(snapshot.val()));
            };
            const errorHandler = (e) => {
                setError(e);
            };
            const loaded = () => {
                setIsLoading(false);
            };
            read(path)
                .then(handler)
                .catch(errorHandler)
                .finally(loaded);
        }
    }, [on, path]);
    return {
        isLoading,
        data,
        error
    };
}