import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import PeopleIcon from '@mui/icons-material/People';
import Collapse from '@mui/material/Collapse';

import PickupPlacePart from './PickupPlacePart';

import {useGuideContext} from '../../controller/context/GuideContext';
import {useFilterContext} from '../../controller/context/FilterContext';
import IconButton from '@mui/material/IconButton';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import {useCallback, useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ChatIcon from '@mui/icons-material/Chat';
import ButtonBase from '@mui/material/ButtonBase';
import {useNavigate} from 'react-router-dom';
import {useDateContext} from '../../controller/context/DateContext';
import {callFunction} from '../../controller/firebase';
import Modal from "@mui/material/Modal";
import {Paper} from "@mui/material";
import {Create} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

const OPERATOR = Object.freeze([
    {
        id: 'SX1njRz4BbevBd6Exb9Z07U7F5D2',
        name: '박호영',
        nameEn: 'Dylan',
        type: 'Operator',
    },
    {
        id: 'Spt1OZhXCvMLKmjGpFLQldoTQk22',
        name: '이영훈',
        nameEn: 'Younghun',
        type: 'Operator',
    },
    {
        id: 'Vermu2kOJeNZiqEm6JBPd8yul6N2',
        name: '박영규',
        nameEn: 'Rupert',
        type: 'Operator',
    },
    {
        id: 'hut7yktlwbbBPTUrKZo2zlsT3YQ2',
        name: '정혜민',
        nameEn: 'Christine',
        type: 'Operator',
    },
    {
        id: 'WMVO4cwqr2gCICd0EDmwJyrczp13',
        name: '최단',
        nameEn: 'Dan',
        type: 'Operator',
    },
    {
        id: 'BmVRwOul3KNJplS0Fz6hGXMgiwm2',
        name: '조한진',
        nameEn: 'Josh',
        type: 'Operator',
    },
    {
        id: '2EM3P99KLJdXbplsdxr6pCmAxXy1',
        name: '권윤희',
        nameEn: 'Jennifer',
        type: 'Operator',
    },
    {
        id: 'o6qwrWYEyOTrX7AyPrcVK5f8oc72',
        name: 'Ktourstory',
        nameEn: 'Ktourstory',
        type: 'Operator',
    },
]);

const ACCOUNTANTS = Object.freeze([
    {
        id: 'wSe5st6Bj8UGvvXl3SBS1liY9RV2',
        name: '김서희',
        nameEn: 'Eliana',
        type: 'Operator',
    },
    {
        id: 'rZhkdyxyFHXjpttuxNzAdNrAgY23',
        name: '김주혜',
        nameEn: 'Juhae',
        type: 'Operator',
    },
]);

export default function TeamPart({idx, product, productId, team, isMyTour, live, searchKeyword}) {
    const navigate = useNavigate();

    const {guideMap, guide} = useGuideContext();
    const {pickupOption, mine} = useFilterContext();
    const {date, dashFormat, setDate} = useDateContext();
    const isMyTeam = team.guides?.includes(guide.id);
    const [open, setOpen] = useState(mine);
    const handleToggleOpen = useCallback(
        (e) => {
            setOpen((v) => !v);
        },
        [team.id],
    );

    const [loadingCreateChat, setLoadingCreateChat] = useState(false);
    const [chatId, setChatId] = useState(null);
    const guides = team.guides
        .filter((g) => !!g)
        .map((gId) => {
            const guide = guideMap?.get(gId);
            return guide ? {name: guide.name, id: guide.id, nameEn: guide.nameEn, type: 'Guide'} : null;
        })
        .filter((g) => !!g);
    return (
        <>
            <ListSubheader
                key={team.id}
                sx={{
                    zIndex: 900,
                    top: 104,
                    p: 0,
                }}
            >
                <Box
                    sx={{
                        px: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={handleToggleOpen}
                    >
                        <Box display={'flex'}>
                            <Chip
                                sx={{
                                    border: 'none',
                                    marginRight: 1,
                                    '& .MuiChip-label': {
                                        marginLeft: 0.5,
                                        paddingRight: 0,
                                    },
                                }}
                                size={'small'}
                                color={'primary'}
                                variant={'outlined'}
                                icon={<PeopleIcon/>}
                                label={
                                    (team.people !== team.shownPeople
                                        ? `${team.shownPeople}/${team.people}`
                                        : team.people) + `(${team.adult}/${team.kid}/${team.infant})`
                                }
                            />

                            <Typography
                                sx={{
                                    width: '100%',
                                }}
                                color={'primary'}
                            >
                                {team.guides
                                    .map((g) =>
                                        guideMap.get(g) ? `${guideMap.get(g).name}(${guideMap.get(g).nameEn})` : g,
                                    )
                                    .join(' | ')}
                            </Typography>
                            {/*{guide.admin || isMyTeam || isMyTour ? (*/}
                            {/*    <ButtonBase*/}
                            {/*        sx={(theme) => ({*/}
                            {/*            color: theme.palette.primary.main,*/}
                            {/*            ml: 0.5,*/}
                            {/*        })}*/}
                            {/*        size={'small'}*/}
                            {/*        onClick={async (e) => {*/}
                            {/*            e.stopPropagation();*/}
                            {/*            e.preventDefault();*/}
                            {/*            if (!guide || !team.guides || !Array.isArray(team.guides)) return;*/}
                            {/*            if (!window?.confirm('채팅을 여시겠습니까?')) return;*/}
                            {/*            async function recallChat() {*/}
                            {/*                setLoadingCreateChat(true);*/}
                            {/*                if (chatId) return chatId;*/}
                            {/*                const chat = await callFunction('recallChat', {*/}
                            {/*                    category: 'TEAM',*/}
                            {/*                    cId: `${dashFormat}:${productId}:${team.id}`,*/}
                            {/*                    title: `${dashFormat}:${product}:${idx + 1}`,*/}
                            {/*                    participants: [*/}
                            {/*                        ...(guides ?? []),*/}
                            {/*                        ...OPERATOR,*/}
                            {/*                        {*/}
                            {/*                            name: guide?.name,*/}
                            {/*                            id: guide?.id,*/}
                            {/*                            nameEn: guide?.nameEn,*/}
                            {/*                            type: guide?.level > 1 ? 'Guide' : 'Operator',*/}
                            {/*                        },*/}
                            {/*                    ],*/}
                            {/*                    tour: {*/}
                            {/*                        date: dashFormat,*/}
                            {/*                        productId: productId,*/}
                            {/*                        team: team.id,*/}
                            {/*                    },*/}
                            {/*                });*/}

                            {/*                if (chat && chat.id) {*/}
                            {/*                    setChatId(chat.id);*/}
                            {/*                    return chat.id;*/}
                            {/*                } else {*/}
                            {/*                    return null;*/}
                            {/*                }*/}
                            {/*            }*/}

                            {/*            recallChat()*/}
                            {/*                .then((chatId) => {*/}
                            {/*                    try {*/}
                            {/*                        if (!chatId) return alert('적절한 채팅방을 찾을 수 없습니다.');*/}
                            {/*                        navigate(`/chat/rooms/${chatId}`);*/}
                            {/*                    } catch (e) {*/}
                            {/*                        alert('채팅방을 열 수 없습니다.');*/}
                            {/*                    }*/}
                            {/*                })*/}
                            {/*                .finally(() => {*/}
                            {/*                    setLoadingCreateChat(false);*/}
                            {/*                });*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {loadingCreateChat ? (*/}
                            {/*            <CircularProgress*/}
                            {/*                size={'small'}*/}
                            {/*                sx={{*/}
                            {/*                    width: '20px',*/}
                            {/*                    height: '20px',*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        ) : (*/}
                            {/*            <ChatIcon*/}
                            {/*                sx={{*/}
                            {/*                    width: '20px',*/}
                            {/*                    height: '20px',*/}
                            {/*                    mr: 0.5,*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        )}*/}
                            {/*    </ButtonBase>*/}
                            {/*) : null}*/}
                        </Box>
                        <IconButton
                            sx={{
                                transform: `rotate(${open ? 180 : 360}deg)`,
                                marginRight: -1.5,
                            }}
                        >
                            {open ? <UnfoldLessIcon/> : <UnfoldMoreIcon/>}
                        </IconButton>
                    </Box>
                </Box>
            </ListSubheader>
            <Collapse in={open} unmountOnExit={!isMyTeam} timeout={0}>
                {team.pickupPlaces
                    .filter(
                        (pickupPlace) =>
                            (pickupOption === 'all' || pickupOption === pickupPlace.key.toLowerCase()) &&
                            (!searchKeyword ||
                                Object.values(pickupPlace.reservations).filter((r) =>
                                    r.toString().toLowerCase().includes(searchKeyword.toLowerCase()),
                                ).length > 0),
                    )
                    .map((pickupPlace) => (
                        <PickupPlacePart
                            key={pickupPlace.key}
                            pickupPlace={pickupPlace}
                            live={live}
                            isMyTeam={isMyTeam}
                            belongMyTour={isMyTour}
                            searchKeyword={searchKeyword}
                            productId={productId}
                            teamId={team.id}
                            date={dashFormat}
                        />
                    ))}
            </Collapse>

        </>
    );
}
