import {useEffect, useState} from 'react';
import {read} from '../index';

export default function useFRead(path, format = f => f, defaultValue) {
    const [data, setData] = useState(defaultValue ?? format(null));
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        const handler = (snapshot) => {
            setData(format(snapshot.val()));
        };
        const errorHandler = (e) => {
            setError(e);
        };
        const loaded = () => {
            setIsLoading(false);
        };
        read(path)
            .then(handler)
            .catch(errorHandler)
            .finally(loaded);
    }, [path]);
    return {
        isLoading,
        data,
        error
    };
}