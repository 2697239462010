import {BottomNavigation, BottomNavigationAction, Box, useTheme} from "@mui/material"
import ChatIcon from "@mui/icons-material/Chat"
import PersonIcon from "@mui/icons-material/Person"
import { useLocation, useNavigate } from "react-router-dom"
import {memo, SyntheticEvent, useState} from "react";
 function Footer() {
    const location = useLocation()
    const navigate = useNavigate()
    const [value,setValue] = useState(
        location.pathname === "/chat/rooms" ? 0 : 1
    )

    const handleChange = (_: SyntheticEvent, newValue: number) => {
        setValue(newValue);

    };
    const handleNavigate = (path:string) => {
        navigate(path)

    }
    return (
        <BottomNavigation  sx={{
            height:"80px",
            justifyContent:"space-around",
            borderTop: "1px solid #E5E5E5",
        }}
       value={value}
       onChange={handleChange}
        >
            <BottomNavigationAction sx={{
                maxWidth: "none"
            }} value={0} icon={
                <ChatIcon sx={{
                   color: value !== 0 ? "#E0E0E0" : 'inherit'
                }}/>
            } onClick={() => handleNavigate('/chat/rooms')} />
            <BottomNavigationAction sx={{
                maxWidth: "none"
            }}  value={1} icon={
                <PersonIcon sx={{
                    color: value !== 1 ? "#E0E0E0" : 'inherit'
                }}/>
            } onClick={() => handleNavigate('/chat')} />

        </BottomNavigation>
    )
}

export const MemoizedFooter = memo(Footer)
export default MemoizedFooter