import * as Sentry from '@sentry/react';
import { initializeApp, getApps } from 'firebase/app';
import {
    equalTo,
    get,
    getDatabase,
    runTransaction,
    onValue,
    orderByKey,
    orderByValue,
    orderByPriority,
    orderByChild,
    query,
    startAt,
    startAfter,
    endAt,
    endBefore,
    limitToFirst,
    limitToLast,
    ref,
    set,
    push,
    update,
    remove,
} from 'firebase/database';

import { getFirestore, collection, doc, updateDoc } from 'firebase/firestore';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    setPersistence,
    AuthErrorCodes,
    browserLocalPersistence,
    initializeAuth,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
} from 'firebase/auth';

import { getMessaging, getToken, onMessage, isSupported, deleteToken } from 'firebase/messaging';

import { getFunctions, httpsCallable } from 'firebase/functions';

import { getAnalytics, logEvent } from 'firebase/analytics';
import {getDownloadURL, getStorage, ref as storageRef, uploadBytesResumable} from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const apps = getApps();
const app = apps.length > 1 ? apps[0] : initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export function reportAnalytics(eventName, message, params = {}) {
    logEvent(analytics, eventName, { message, ...params });
}

export function reportLog(message, params = {}) {
    reportAnalytics('system', message, params);
}

export function reportError(error, params = {}) {
    reportAnalytics(error.name ? `err_${error.name}` : 'error', error.message ?? error.toString(), {
        name: error.name ?? '',
        ...params,
    });
    Sentry.captureException(error);
    console.error(error);
}

const database = getDatabase();

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
const auth = getAuth(app);

export const onAuthStateChange = (handler) => {
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            if (window.Notification?.permission === 'granted') {
                renewToken().catch((e) => {
                    reportError(e);
                });
            }
        }
        handler(user);
    });
};

export async function login(email, password) {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);

        if (userCredential.user) {
            await requestNotificationPermission().catch((e) => {
                reportError(e);
                alert(`Error occurs while register device. ${e.toString()}`);
            });
        }
        return userCredential.user;
    } catch (e) {
        switch (e.code) {
            case AuthErrorCodes.INVALID_PASSWORD:
            case AuthErrorCodes.INVALID_EMAIL:
            case AuthErrorCodes.NULL_USER:
                window?.alert('Please check email and password.' + '\r\n' + e.code);
                break;
            case AuthErrorCodes.USER_CANCELLED:
            case AuthErrorCodes.USER_DELETED:
            case AuthErrorCodes.USER_MISMATCH:
            case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
            case AuthErrorCodes.USER_SIGNED_OUT:
            default:
                window?.alert('Please sign in again.' + '\r\n' + e.code);
        }
    }
}

export async function loginWithGoogle() {
    try {
        await setPersistence(auth, browserLocalPersistence);
        const result = await signInWithPopup(auth, provider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        return result.user;
    } catch (e) {
        alert(e.message ?? e.code);
        alert('Something goes wrong. Please alert it to admin.');
    }
}

export async function register(email, password) {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        alert('Register processed. Please wait admin approve your registration.');
        return userCredential.user;
    } catch (e) {
        reportError(e);
        alert(e.message ?? e.code);
        alert('Something goes wrong. Please alert it to admin.');
    }
}

export async function reset(email) {
    await sendPasswordResetEmail(auth, email);
}

export async function logout() {
    return await signOut(auth);
}

export function getCurrentUser() {
    return auth.currentUser;
}

export async function read(path) {
    return get(ref(database, path));
}

export async function readWidthKeyStartToEnd(path, startKey, endKey) {
    return get(query(ref(database, path), orderByKey(), startAt(startKey), endAt(endKey)));
}

export async function readWidthKeyStartWithLimit(path, startKey, limit) {
    return get(query(ref(database, path), orderByKey(), startAt(startKey), limitToFirst(limit)));
}

export async function readWithChildValue(path, child, value) {
    return get(query(ref(database, path), orderByChild(child), equalTo(value)));
}

export function listen(path, callback, errorHandler) {
    return onValue(ref(database, path), callback);
}

export function listenWithChildValue(path, child, value, callback, errorHandler) {
    return onValue(query(ref(database, path), orderByChild(child), equalTo(value)), callback);
}

export async function setVal(path, value) {
    return set(ref(database, path), value);
}

export async function updateVal(path, value) {
    return update(ref(database, path), value);
}

export async function removeVal(path) {
    return remove(ref(database, path));
}

export async function pushVal(parentPath, value) {
    return push(ref(database, parentPath), value);
}

export async function pushKey(parentPath) {
    return push(ref(database, parentPath)).key;
}

export async function transaction(path, job) {
    return runTransaction(ref(database, path), job);
}

export async function requestNotificationPermission() {
    if (!(await isSupported())) return;
    if (!window.Notification) {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const iphone = /iPhone|iPad|iPod|macintosh/i.test(userAgent);
        if (iphone) {
            alert("If you'd like to receive notifications  Select 'Add Home Screen' to download the app.");
        } else {
            alert('Cannot use notification. Please use other web agent.');
        }
        return;
    }
    let permission = window.Notification.permission;

    if (permission !== 'granted') {
        permission = await Notification.requestPermission();
    }

    if (permission === 'granted') {

        //todo - Permission request와 이중으로 처리하고 싶으나, racecondition에 의해, token 등록이 ios에서 누락될 수 있기 때문에, 살려둠
        //todo 이로 인해서 이중으로 토큰이 잡히는 현상이 있음

        try {
            await getFirebaseToken();
        } catch (e) {
            reportError(e);
            //todo - 알림 허용을 목적으로 재시도 로직 pushManager, No Active Service Worker 에러가 없어지면, 제거 고려
            await new Promise((resolve) => {
                setTimeout(() => {
                    getFirebaseToken()
                        .then(resolve)
                        .catch((e) => {
                            reportError(e);
                        });
                }, 300);
            });
        }
    } else {
        if (getCurrentUser()?.uid) {
            const userAgent = window.navigator.userAgent.toLowerCase();
            const iphone = /iPhone|iPad|iPod|macintosh/i.test(userAgent);
            if (iphone) {
                alert('Please go settings > notification > KTOURSTORY GUIDE, and enable receive notification.');
            } else {
                alert('Please enable notification at settings.');
            }
        }
    }
}

export async function requestPermissionIfNeedOnUserTrigger() {
    if ((await isSupported()) && window.Notification && window.Notification.permission !== 'granted')
        await requestNotificationPermission().catch((e) => {
            reportError(e);
        });
}

async function getMessagingService() {
    return (await isSupported()) ? getMessaging(app) : null;
}

async function getFirebaseToken() {
    const messaging = await getMessagingService();

    if (!messaging || !getCurrentUser()?.uid) return;

    const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        //scope를 필요한 /firebase-cloud-messaging-push-scope로 한정하여 등록
    });
    //todo 목표 불명확한 token/ 인덱스
    setVal(`token/${currentToken}`, getCurrentUser()?.uid).catch((e) => reportError(e));
    setVal(`user/${getCurrentUser()?.uid}/messageIds/${currentToken}`, Date.now()).catch((e) => reportError(e));
}

async function renewToken() {
    const messaging = await getMessagingService();

    if (!messaging || !getCurrentUser()?.uid) return;
    //scope를 필요한 /firebase-cloud-messaging-push-scope로 한정하여 등록할 떄 이용한 scope가 있는지 확인

    const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
    //todo 목표 불명확한 token/ 인덱스
    setVal(`token/${currentToken}`, getCurrentUser()?.uid).catch((e) => reportError(e));
    setVal(`user/${getCurrentUser()?.uid}/messageIds/${currentToken}`, Date.now()).catch((e) => reportError(e));
}

export async function removeUserToken(){
    const messaging = await getMessagingService();
    await deleteToken(messaging);
}


const firestore = getFirestore(app);

export function docWithBaseFireStore(collection, id) {
    return doc(firestore, collection, id);
}

export function collectionWithBaseFireStore(col) {
    return collection(firestore, col);
}

export async function updateFireStore(collection, id, updates) {
    await updateDoc(docWithBaseFireStore(collection, id), updates);
}

const functions = getFunctions(app);

export async function callFunction(name, data) {
    const callable = httpsCallable(functions, name, { timeout: 540000 });
    const result = await callable(data);
    return result.data;
}

export async function catchMessage(callback) {
    const messaging = await getMessagingService();

    if (!messaging || !getCurrentUser()?.uid) return;
    return onMessage(messaging, (p) => {
        const chatId = p.data?.chatId ?? p.chatId;
        const name = p.data?.name ?? p.name;
        const body = p.data?.body ?? p.body;
        if (!chatId) return null;
        const regex = new RegExp(/chat\/(?!$).*/);
        if (!regex.test(window.location.pathname)) {
            return callback?.(`${name}\n${body}}`, chatId);
        }
        return null;
    });
}

const storage = getStorage(app);

export async function uploadFileToStorage(path, file, onProgress) {
    return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(storageRef(storage, path), file);
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress(progress, snapshot.bytesTransferred, snapshot.totalBytes);
            },
            (error) => {
                reject(error);
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                resolve(downloadUrl);
            },
        );
    });
}
