import {useRouteError} from 'react-router-dom';

export default function ErrorPage() {
    const error = useRouteError();
    return (
        <div id="error-page">
            <h1>에러</h1>
            <p>아래 에러가 발생하였습니다.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}