import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TourAccordion from './TourAccordion';
import {useFilterContext} from '../../controller/context/FilterContext';



export default function TourStack({tours, searchKeyword, productMap}) {
    const{defaultLive, mine, } = useFilterContext()
    const filtered = tours.filter((tour) => tour.teamInfos.people);
    return (
        <Stack
        >
            {
                filtered.length > 0
                    ? filtered
                        .map((tour) =>
                            <TourAccordion key={tour.id + tour.snapshotedAt.toString() + defaultLive}
                                           defaultTour={tour} searchKeyword={searchKeyword} productMap={productMap}
                            />)
                    : (
                        <Box
                            pt={10}
                        >
                            <Typography
                                variant={'h6'}
                                sx={{fontSize: '1.2rem'}}
                                color={'secondary'}
                                textAlign={'center'}
                            >
                                해당 투어 내용이 없습니다.<br/> No tour on this day.
                            </Typography>
                        </Box>
                    )
            }
        </Stack>
    );
}