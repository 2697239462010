import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { UserSearch } from '../home/UserSearch';
import Tabs from '../../ui/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../ui/TabPanel';
import Button from '@mui/material/Button';
import { DrawerContext } from '../../../context/DrawerContext';
import { GuideContext } from '../../../controller/context/GuideContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { convertSenderName, parseChatMetadata, parseParticipantData } from '../../../controller/chat/utils';
import { ICategory, IGuide, IParticipant } from '../../../types/chat';
import { recallChat, sendMessage } from '../../../controller/chat';
import { callFunction, readRealtime } from '../../../controller/chat/firebase';

interface Props {
    participants: IParticipant[];
    category: ICategory;
}

export default function AddParticipant(data: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { chatId } = useParams() as { chatId: string };
    const { guide } = useContext(GuideContext) as any;

    const _participants = data.participants.filter((user) => user.id !== guide.id);
    const _category = data.category.toLowerCase();

    const [guiders, setGuiders] = useState<IGuide[]>([]);
    const [operators, setOperators] = useState<IGuide[]>([]);
    const [oldGuiders, setOldGuiders] = useState<IGuide[]>([]);
    const [oldOperators, setOldOperators] = useState<IGuide[]>([]);
    const [tabValue, setTabValue] = useState(0);
    const [selectedUser, setSelectedUser] = useState<IGuide[]>([]);
    const [loading, setLoading] = useState(false);

    const { state, dispatch } = useContext(DrawerContext);

    const { guide: currentUser } = useContext(GuideContext) as any;

    useEffect(() => {
        (async () => {
            const users = await readRealtime('user');

            const participantIds = _participants.map((participant: any) => participant.id);
            const filteredUsers = Object.values(users as object)
                .filter((user: any) => user.id !== currentUser?.id)
                .map((user) => ({
                    ...user,
                    raw: JSON.parse(user.raw),
                }));
            const filteredIsNotParticipants = filteredUsers.filter((user: any) => !participantIds.includes(user.id));
            const operators = filteredIsNotParticipants.filter((user) => user.level <= 1 ); // 가이드는 2 이상
            const guiders = filteredIsNotParticipants.filter((user) => user.level > 1);

            setGuiders(guiders);
            setOperators(operators);
            setOldGuiders(guiders);
            setOldOperators(operators);
        })();
    }, [data]);

    function handleSearchUser(searchKeyword: string) {
        if (searchKeyword === '') {
            setGuiders(oldGuiders);
            setOperators(oldOperators);
            return;
        }
        const regex = new RegExp(searchKeyword, 'gi');

        if (tabValue === 0) {
            const filteredGuiders = oldGuiders.filter((guider) => {
                return (
                    guider?.name?.match(regex) ||
                    guider?.nameEn?.match(regex) ||
                    (currentUser?.level <= 1 && guider?.tel?.match(regex))
                );
            });
            setGuiders(filteredGuiders);
        } else {
            const filteredOperators = oldOperators.filter((operator) => {
                return (
                    operator?.name?.match(regex) ||
                    operator?.nameEn?.match(regex) ||
                    (currentUser?.level <= 1 && operator?.tel?.match(regex))
                );
            });
            setOperators(filteredOperators);
        }
    }

    const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleSelectUser = (user: IGuide) => {
        const isExist = selectedUser?.includes(user);
        if (isExist) {
            setSelectedUser((prev) => [...(prev || [])].filter((u) => u.id !== user.id));
            return;
        }
        setSelectedUser((prev) => [...(prev || []), user]);
    };

    const handleCreateGroup = async () => {
        setLoading(true);
        const selectedParticipants: IParticipant[] = selectedUser?.map((user) => parseParticipantData(user));
        const { category, participants, cId, title, tour } = parseChatMetadata({
            participants: [
                ..._participants,
                ...selectedParticipants,
                {
                    id: guide.id,
                    type: guide.level > 1 ? 'guide' : 'operator', // outsource는 가이드로 처리
                    name: guide.name,
                    nameEn: guide.nameEn,
                },
            ],
            category: 'group',
            title: 'GROUP',
        });
        const chat = await recallChat({
            category,
            participants,
            cId,
            title,
            tour,
        });

        setLoading(false);
        dispatch({ type: 'CLOSE' });
        navigate(`/chat/rooms/${chat.id}`);
    };

    const handleAddParticipant = async () => {
        setLoading(true);
        const participants = selectedUser?.map((user) => parseParticipantData(user));
        await callFunction('addParticipantsToChat', {
            chatId,
            participants,
        });

        const message = {
            type: 'enter',
        };

        // TODO: functions로 분리하기
        await sendMessage(chatId, participants, guide, message);
        setLoading(false);
        dispatch({ type: 'CLOSE' });
    };
    return (
        <Box sx={{ width: 300, pb: 4, px: 2 }}>
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#fff',
                    zIndex: 2,
                    py: 4,
                }}
            >
                <Box>
                    <UserSearch handleSearch={handleSearchUser} />
                    <Tabs value={tabValue} onChange={handleChangeTab}>
                        <Tab label="Guide" />
                        <Tab label="Operator" />
                    </Tabs>
                </Box>
                <Button
                    variant="contained"
                    fullWidth={true}
                    onClick={
                        _category !== 'client'
                            ? _participants.length > 1
                                ? handleAddParticipant
                                : handleCreateGroup
                            : handleAddParticipant
                    }
                    disabled={loading || selectedUser.length === 0}
                >
                    {loading ? '초대중...' : '초대'}
                </Button>
            </Box>

            <Box>
                <List
                    sx={{
                        width: '100%',
                    }}
                    subheader={
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            참가자
                        </Typography>
                    }
                >
                    {_participants
                        .filter((p) => p.type === 'operator')
                        .map((participant: any, index: number) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <CheckCircleIcon sx={{ color: 'success.main' }} />
                                </ListItemIcon>
                                <ListItemText primary={convertSenderName(participant, true)} />
                            </ListItem>
                        ))}
                    <Divider />
                    {_participants
                        .filter((p) => p.type === 'guide')
                        .map((participant: any, index: number) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <CheckCircleIcon sx={{ color: 'success.main' }} />
                                </ListItemIcon>
                                <ListItemText primary={convertSenderName(participant, true)} />
                            </ListItem>
                        ))}
                    <Divider />
                    {_participants
                        .filter((p) => p.type === 'client')
                        .map((participant: any, index: number) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <CheckCircleIcon sx={{ color: 'success.main' }} />
                                </ListItemIcon>
                                <ListItemText primary={convertSenderName(participant, true)} />
                            </ListItem>
                        ))}
                </List>
                <TabPanel index={0} value={tabValue}>
                    <List
                        sx={{
                            width: '100%',
                        }}
                        subheader={
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                가이드
                            </Typography>
                        }
                    >
                        {currentUser &&
                            guiders.map((guide, index) => (
                                <User
                                    key={index}
                                    user={guide}
                                    isSelected={selectedUser?.includes(guide)}
                                    onClick={() => handleSelectUser(guide)}
                                />
                            ))}
                    </List>
                </TabPanel>
                <TabPanel index={1} value={tabValue}>
                    <List
                        sx={{
                            width: '100%',
                        }}
                        subheader={
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                관리자
                            </Typography>
                        }
                    >
                        {currentUser &&
                            operators.map((operator, index) => (
                                <User
                                    key={index}
                                    user={operator}
                                    isSelected={selectedUser?.includes(operator)}
                                    onClick={() => handleSelectUser(operator)}
                                />
                            ))}
                    </List>
                </TabPanel>
            </Box>
        </Box>
    );
}

function User({ user, isSelected, onClick }: { user: any; isSelected: boolean; onClick: () => void }) {
    return (
        <ListItemButton onClick={onClick}>
            <ListItemIcon>
                <CheckCircleIcon
                    sx={{
                        color: isSelected ? 'success.main' : 'inherit',
                    }}
                />
            </ListItemIcon>
            <ListItemText primary={`${user.name}(${user.nameEn})`} />
        </ListItemButton>
    );
}
