import {useEffect, createContext, useCallback, useContext, useMemo, useState, useLayoutEffect} from 'react';

export const FilterContext = createContext({
    areaOption: 'all',
    pickupOption: 'all',
    defaultLive: false,
    mine: true,
});

export function useFilterContext() {
    return useContext(FilterContext);
}