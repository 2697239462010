import {Outlet, Redirect, useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";

import Bar from "./Bar";
import Loading from "../../components/Loading";

import {useGuideContext} from "../../controller/context/GuideContext";
import {useDateContext} from "../../controller/context/DateContext";
import React, {useEffect} from "react";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import Button from "@mui/material/Button";
import {catchMessage} from "../../controller/firebase/index";

export default function Root({}) {
    const {
        user,
        isInitialized,
        guide,
        login,
        register,
        reset,
        loginWithGoogle,
        logout,
    } = useGuideContext();
    const {date, dashFormat, setDate} = useDateContext();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const unsub = await catchMessage((message, chatId) => {
                return enqueueSnackbar(message, {
                    className: "message-snackbar",
                    action: (snackbarId) => {
                        return (
                            <>
                                <Button
                                    variant="text"
                                    onClick={() => {
                                      closeSnackbar(snackbarId);
                                      navigate(`/chat/rooms/${chatId}`)
                                    }}
                                >
                                    Go to Chat
                                </Button>
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={() => closeSnackbar(snackbarId)}
                                >
                                    Dismiss
                                </Button>
                            </>
                        );
                    },
                });
            });

            return async () => {
                if (unsub) await unsub();
            };
        })();
    }, []);

    return (
        <Box>
            <Bar
                user={user}
                initialized={isInitialized}
                date={date}
                guide={guide}
                onLogin={login}
                onRegister={register}
                onReset={reset}
                onGoogleLogin={loginWithGoogle}
                onLogout={logout}
                onChangeDate={setDate}
            />
            <Box pt={"56px"}>{guide ? <Outlet/> : null}</Box>
            {!isInitialized ? <Loading/> : null}
        </Box>
    );
}
