import {useState} from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';

import {useGuideContext} from '../../controller/context/GuideContext';
import useFReadWithChildVals from '../../controller/firebase/hook/useFReadWithChildVals';

import Account from '../../model/Account';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const accountValsToAccountList = (accountVals) => {
    if (!accountVals) return [];
    return Object.entries(accountVals).map(([accountId, rawAccount]) => new Account(rawAccount, accountId, '/account'));
};


export default function WeekBalanceList({week, defaultExpanded = false}) {
    const {guide} = useGuideContext();
    const firstDate = week[0];
    const lastDate = week[6];
    const identifiers = week.map(([date, schedule]) => date + (guide?.email ?? '').split('@')[0]);
    const {data} = useFReadWithChildVals('/account', 'identifier', identifiers, accountValsToAccountList);
    const accounts = Object.entries(data).map(([_, data]) => data).flat(1).filter(i => !!i);
    const {
        card,
        cash
    } = accounts.reduce((rslt, account) => ({
        card: rslt.card + (account?.card ?? 0),
        cash: rslt.cash + (account?.cash ?? 0)
    }), {card: 0, cash: 0});
    return (

        <Accordion
            defaultExpanded={defaultExpanded}
            TransitionProps={{
                timeout: 0,
                unmountOnExit: true,
            }}
            sx={{
                margin: '0px !important'
            }}
        >
            <AccordionSummary
                sx={{
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    zIndex: 1000,
                    py: 1,
                    px: 2,
                    background: 'white',
                    justifyContent: 'stretch',
                    '& .MuiAccordionSummary-content': {
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0px !important'
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        marginRight: -0.5
                    }
                }}
                expandIcon={<ExpandMoreIcon/>}
            >
                <Box display={'box'} flex={1}>
                    <Typography
                        gutterBottom
                        variant={'subtitle2'}
                    >
                        {`${firstDate[0]} ~ ${lastDate[0]}`}
                    </Typography>
                    <Box
                        display={'flex'}
                        justifyContent={'space-evenly'}
                    >
                        <Typography
                            fontWeight={600}
                            sx={{
                                fontSize: '1rem',
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <Icon sx={{mr: 1}}>credit_card</Icon>
                            {(card).toLocaleString()} ₩
                        </Typography>
                        <Typography
                            display={'span'}
                            fontWeight={600}
                            sx={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <Icon sx={{mr: 1}}>money</Icon>
                            {(cash).toLocaleString()} ₩
                        </Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <Divider/>
            <AccordionDetails
                sx={{
                    p: 0
                }}
            >
                <List
                    disablePadding
                >
                    {
                        accounts.map((account, idx, accounts) => (
                            <ListItem
                                key={account.id}
                                sx={{
                                    p: 0,
                                    m: 0,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#f9f9fd'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            px: 2,
                                            pt: 1,
                                            pb: 0,
                                        }}
                                    >

                                        <Box>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    color: 'gray',
                                                    fontSize: '10pt',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                {account.time.toLocaleString()}
                                            </Typography>
                                        </Box>
                                        <Box
                                            display={'flex'}
                                            justifyContent={'space-evenly'}
                                        >
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'gray',
                                                    fontSize: '10pt',
                                                    fontWeight: '700'
                                                }}
                                            >
                                                <Icon sx={{color: 'gray', mr: 1}}>credit_card</Icon>
                                                {account.card?.toLocaleString()} ₩
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'gray',
                                                    fontSize: '10pt',
                                                    fontWeight: '700'
                                                }}
                                            >
                                                <Icon sx={{color: 'gray', mr: 1}}>money</Icon>
                                                {account.cash?.toLocaleString()} ₩
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: 'gray',
                                                    fontSize: '10pt',
                                                    fontWeight: '700',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {account.memo}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {
                                        idx !== accounts.length - 1
                                            ? <Divider/>
                                            : null
                                    }
                                </Box>
                            </ListItem>
                        ))
                    }
                </List>
            </AccordionDetails>
            <Divider/>
        </Accordion>
    );
}
