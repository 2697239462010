export default function sanitizeFileName(filename) {
    // 윈도우와 다른 운영 체제에서 유효하지 않은 문자들을 정의
    const invalidChars = /[<>:"\/\\|?*\x00-\x1F]/g;

    // 파일 이름 끝에 위치할 수 없는 문자들을 정의
    const invalidEnd = /[. ]$/;

    // 유효하지 않은 문자들을 공백으로 치환
    let sanitized = filename.replace(invalidChars, " ");

    // 파일 이름이 유효하지 않은 문자로 끝나면 제거
    if (invalidEnd.test(sanitized)) {
        sanitized = sanitized.slice(0, -1);
    }

    return sanitized;
}

