import { Avatar } from '@mui/material';
import { red, blue } from '@mui/material/colors';
import { IGuide, IUser } from '../../../types/chat';
interface Props {
    user: IUser;
}
export default function UserAvatar({ user }: Props) {
    return (
        <Avatar
            sx={{
                bgcolor: (user.area?.at(0)?.toUpperCase() ?? 'S') === 'S' ? red['500'] : blue['500'],
            }}
        >
            {user.area?.at(0)?.toUpperCase() ?? 'S'}
        </Avatar>
    );
}
