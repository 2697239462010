import {useState, useCallback, useMemo, useRef} from "react"
import {Link} from "react-router-dom"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ButtonBase from "@mui/material/ButtonBase"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker"

import CloseMenuIcon from "@mui/icons-material/FirstPage"
import SignOutIcon from "@mui/icons-material/Logout"
import SignInIcon from "@mui/icons-material/Login"
import MenuIcon from "@mui/icons-material/Menu"
import TourIcon from "@mui/icons-material/Tour"
import PaymentsIcon from "@mui/icons-material/Payments"
import BalanceIcon from "@mui/icons-material/Balance"
import ChatIcon from "@mui/icons-material/Chat"
import WorkIcon from "@mui/icons-material/Work"
import MonitorIcon from "@mui/icons-material/Monitor"
import GoogleIcon from "@mui/icons-material/Google"

import {useGuideContext} from "../../controller/context/GuideContext"
import {requestNotificationPermission, requestNotificationPermissionWithInstruction} from "../../controller/firebase";
import * as Sentry from "@sentry/react";

function LoginDialog({user, initialized,  guide, onLogin, onRegister, onReset, onGoogleLogin, onLogout}) {
    const [onInterval, setOnInterval] = useState(false);
    const [logInDialogOpen, setLogInDialogOpen] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const ref = useRef(null)

    const handleLoginModalToggle = useCallback((e) => {
        setLogInDialogOpen((open) => !open)
    }, [])

    const handleLogin = (e) => {
        e.preventDefault()
        setOnInterval(true);
        onLogin(e.target.email.value, e.target.password.value);
        setTimeout(() => {
            setOnInterval(false);
        }, 1500)
    }

    const handleRegister = (e) => {
        e.preventDefault()
        if (!email.endsWith("gmail.com")) {
            return alert("Email must ends with gmail.com")
        }
        setOnInterval(true);
        onRegister(email, password);
        setTimeout(() => {
            setOnInterval(false);
        }, 1500)
    }

    const handleReset = (e) => {
        e.preventDefault()
        if (!email.endsWith("gmail.com")) {
            return alert("Email must ends with gmail.com")
        }
        setOnInterval(true);
        onReset(email)
        setTimeout(() => {
            setOnInterval(false);
        }, 1500)
        alert("Please check email to reset your passwords.")
    }

    const handleGoogleLogin = (e) => {
        e.preventDefault()
        onGoogleLogin()
    }

    const handleReload = ()=>{
        window?.location?.reload();
    }
    const handleLogout = () =>{
        onLogout();
    }


    return (
        <>
            {guide ? (
                <IconButton color="inherit" aria-label="log out" onClick={onLogout} edge="end">
                    <SignOutIcon/>
                </IconButton>
            ) : (
                <IconButton color="inherit" aria-label="log in" onClick={handleLoginModalToggle} edge="end">
                    <SignInIcon/>
                </IconButton>
            )}

            {
                initialized && user? (
                        <Dialog
                            PaperProps={{
                                component: "form",
                            }}
                            open={!guide || logInDialogOpen}
                            onClose={handleLoginModalToggle}
                        >
                            <DialogContent ref={ref}>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <Typography variant={"body1"} align={"center"}>
                                        After admin approve your account, Please reload this page
                                    </Typography>
                                </Box>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button type={"button"} onClick={handleLogout} disabled={onInterval}>
                                    Logout
                                </Button>
                                <Button type={"button"} onClick={handleReload} disabled={onInterval}>
                                    RELOAD
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    :(
                        <Dialog
                            PaperProps={{
                                component: "form",
                            }}
                            open={!guide || logInDialogOpen}
                            onClose={handleLoginModalToggle}
                            onSubmit={handleLogin}
                            disabled={onInterval}
                        >
                            <DialogTitle>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography variant={"h6"}>Login</Typography>
                                    <ButtonBase size={"small"} onClick={handleGoogleLogin}>
                                        <Typography variant={"caption"}>Operator</Typography>
                                    </ButtonBase>
                                </Box>
                            </DialogTitle>
                            <DialogContent ref={ref}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    margin={"dense"}
                                    id={"email"}
                                    label={"Email"}
                                    type={"email"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    margin={"dense"}
                                    id={"password"}
                                    label={"Password"}
                                    type={"password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <ButtonBase onClick={handleReset} disabled={onInterval}>
                                        <Typography variant={"caption"} align={"right"}>
                                            Reset Passwords
                                        </Typography>
                                    </ButtonBase>
                                </Box>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button type={"button"} onClick={handleRegister} disabled={onInterval}>
                                    Register
                                </Button>
                                <Button type={"submit"} disabled={onInterval}>
                                    LOGIN
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
            }
        </>
    )
}

function MenuDrawer({}) {
    const {guide} = useGuideContext()
    const [drawerOpen, setDrawerOpen] = useState(false)

    const handleDrawerToggle = useCallback((e) => {
        setDrawerOpen((open) => !open)
    }, [])

    return (
        <>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} edge="start">
                <MenuIcon/>
            </IconButton>
            <Drawer
                open={drawerOpen}
                variant={"temporary"}
                anchor={"left"}
                onClose={handleDrawerToggle}
                onClick={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    // width:240
                    "& .MuiDrawer-paper": {boxSizing: "border-box", width: 240},
                }}
            >
                <Toolbar
                    sx={{
                        flexDirection: "row",
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        {
                            window.Notification?.permission !== 'granted'
                                ? <Button onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    requestNotificationPermission().catch((e) => {
                                        Sentry.captureException(e);
                                        alert(`Error occurs while register device. ${e.t용oString()}`)
                                    });
                                }}>Allow Notification</Button>
                                : null
                        }
                    </Box>
                    <CloseMenuIcon/>
                </Toolbar>
                <Divider/>
                <List>
                    {
                        !guide?.promotion && !guide?.bus
                            ? (
                                <ListItem disablePadding>
                                    <ListItemButton component={Link} to={"/"}>
                                        <ListItemIcon>
                                            <TourIcon/>
                                        </ListItemIcon>
                                        <ListItemText>TOUR</ListItemText>
                                    </ListItemButton>
                                </ListItem>)
                            : null
                    }
                    {
                        guide?.bus
                            ? (
                                <ListItem disablePadding>
                                    <ListItemButton component={Link} to={"/"}>
                                        <ListItemIcon>
                                            <TourIcon/>
                                        </ListItemIcon>
                                        <ListItemText>BUS TEAM</ListItemText>
                                    </ListItemButton>
                                </ListItem>)
                            : null
                    }
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to={"/payment"}>
                            <ListItemIcon>
                                <PaymentsIcon/>
                            </ListItemIcon>
                            <ListItemText>PAYMENT</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to={"/balance"}>
                            <ListItemIcon>
                                <BalanceIcon/>
                            </ListItemIcon>
                            <ListItemText>BALANCE</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to={"/chat/rooms"}>
                            <ListItemIcon>
                                <ChatIcon/>
                            </ListItemIcon>
                            <ListItemText>CHAT</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    )
}

function BarDate({date, onChange, guide}) {
    const [barDate, setBarDate] = useState(date)
    const handleChangeBarDate = useCallback((datelike) => {
        if (guide.admin || guide.outsource || guide.cs || guide.bus) {
            return setBarDate(datelike)
        }
        // -3일 앞으로 +7

        const newDate = new Date(datelike);
        // todo 106 -> 10
        if (newDate > new Date(Date.now() + (10 + new Date().getHours()) * 60 * 60 * 1000)) {
            alert("Not yet deployed")
            return null
        }
        if (newDate < new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)) {
            alert("Cannot go back more than 3 days")
            return null
        }
        setBarDate(datelike)
    }, [])
    return (
        <MobileDatePicker
            value={barDate}
            onAccept={onChange}
            onChange={handleChangeBarDate}
            inputFormat={"YYYY/MM/DD"}
            showToolbar={false}
            renderInput={(props) => (
                <ButtonBase
                    ref={props.inputRef}
                    onClick={props.onClick}
                    sx={{
                        p: 1,
                    }}
                >
                    <Typography color={"white"}>{props.inputProps.value}</Typography>
                </ButtonBase>
            )}
            // renderInput={(params) => <MuiTextField {...params} />}
        />
    )
}

export default function Bar({user,initialized ,date, onChangeDate, guide, onLogin, onRegister, onLogout, onGoogleLogin, onReset}) {
    return (
        <AppBar
            position={"fixed"}
            elevation={0}
            sx={{
                zIndex: 1100,
            }}
        >
            <Toolbar
                sx={{
                    minHeight: "56px !important",
                }}
            >
                <MenuDrawer/>
                <Box
                    sx={{
                        flexGrow: 1,
                        textAlign: "center",
                    }}
                >
                    <BarDate date={date} guide={guide} onChange={onChangeDate}/>
                </Box>
                <LoginDialog
                    user={user}
                    initialized={initialized}
                    guide={guide}
                    onLogin={onLogin}
                    onRegister={onRegister}
                    onReset={onReset}
                    onGoogleLogin={onGoogleLogin}
                    onLogout={onLogout}
                />
            </Toolbar>
        </AppBar>
    )
}
