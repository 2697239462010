import {useState} from "react";

import useFListen from "../../controller/firebase/hook/useFListen";
import {requestPermissionIfNeedOnUserTrigger, setVal} from "../../controller/firebase";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from "@mui/material/Modal";


export default function ({myTeam, guide, notifications}) {
    const [open, setOpen] = useState(false);
    const {data: liveGuideMemo} = useFListen(true, `${myTeam?.path}/memo/${guide.id}`, (s) => s, '');
    const {data: liveOfficeMessage} = useFListen(true, `${myTeam?.path}/memo/office`, (s) => s, '');


    const handleOpen = () => {
        requestPermissionIfNeedOnUserTrigger().catch(console.error);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleChangeMemo = (e) => {
        setVal(myTeam.path + '/memo/' + guide.id, e.target.value).catch(alert);
    };

    return (
        <>
            <Box
                sx={(theme) => ({
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    height: '56px',
                    color: theme.palette.grey['700'],
                    zIndex: 9999,
                    background: 'white',
                    boxShadow:`1px -3px 9px -1px rgba(0,0,0,0.50)`
                })}
                onClick={handleOpen}
            >
                <Typography
                    color={'inherit'}
                    fontWeight={'600'}
                    sx={{
                        flex: 1,
                        p: 2,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {notifications && notifications[0] ? notifications[0].title : liveOfficeMessage ? `OFFICE: ${liveOfficeMessage}` : 'Memo/Message'}
                </Typography>
            </Box>
            <Modal
                open={open}
                sx={{zIndex: 10000}}
                onClick={handleClose}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '92vw',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Box
                        sx={{
                            background: 'white',
                            height:'80vh',
                            overflowY:'scroll',

                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        {
                            notifications && notifications.length > 0
                                ? notifications.map(({title, body},idx) => {
                                    return (
                                        <Box
                                            key={idx+''}
                                            sx={{
                                                px: 2,
                                                py: 2,
                                                mb: 2,
                                            }}
                                        >

                                            <TextField
                                                fullWidth
                                                disabled
                                                value={title}
                                            />
                                            <TextField
                                                sx={{
                                                    mt: 2
                                                }}
                                                fullWidth
                                                disabled
                                                multiline
                                                rows={7}
                                                value={body}
                                            />
                                        </Box>
                                    )
                                })
                                : null
                        }
                        <Box
                            sx={{
                                px: 2,
                                py: 2,
                            }}
                        >
                            <TextField
                                fullWidth
                                disabled={!myTeam}
                                multiline
                                maxRows={10}
                                minRows={3}
                                defaultValue={liveGuideMemo}
                                label={'Guide Memo'}
                                placeholder={'You can write memo to office on tour'}
                                onChange={handleChangeMemo}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                sx={{
                                    mt: 3
                                }}
                                fullWidth
                                disabled
                                multiline
                                rows={7}
                                defaultValue={liveOfficeMessage}
                                label={'Office Message'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                    <Button
                        fullWidth
                        variant={'contained'}
                        onClick={handleClose}
                    >
                        닫기
                    </Button>
                </Box>
            </Modal>
        </>
    )
}