import {createTheme} from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    typography: {
        fontFamily:`"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif`
    },
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#4a148c',
        },
        error:{
            main:'#FF4C41',
        },
        warning:{
            main:'#FF8744'
        },
        info:{
            main:'#FFCB44',
        },
        success:{
            main:'#55B877'
        },
        text:{
            primary:'#2A2F37',
            secondary:'#333333',
            disabled:'#4F4F4F',
        }
    },
});

export default theme;
