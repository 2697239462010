import {useState} from "react";
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import PeopleIcon from '@mui/icons-material/People';
import Divider from '@mui/material/Divider';
import ReservationItem from './ReservationItem';

export default function PickupPlacePart({teamId, productId, date, pickupPlace, live, isMyTeam, belongMyTour, searchKeyword}) {
    const {pickupName, key, people, shownPeople, reservations, adult, kid, infant} = pickupPlace;
    const [timestamp, setTimestamp] = useState(Date.now);
    const sortedReservations = reservations.filter((r) => (r.toString().toLowerCase().includes(searchKeyword.toLowerCase()))).sort((a, b) => {
        if (a.noShow) return 1;
        if (b.noShow) return -1;
        return a.clientName.localeCompare(b.clientName);
    });

    return (
        <>
            <ListSubheader
                sx={{
                    top: 144,
                    p: 0,
                    zIndex: 800,
                }}
            >
                <Box
                    sx={{
                        px: 2,
                        py: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Chip
                        sx={{
                            border: 'none',
                            marginRight: 1,
                            '& .MuiChip-label': {
                                marginLeft: 0.5,
                                paddingRight: 0
                            }
                        }}
                        size={'small'}
                        color={'secondary'}
                        variant={'outlined'}
                        icon={<PeopleIcon/>}
                        label={(people !== shownPeople ? `${shownPeople}/${people}` : people) + `(${adult}/${kid}/${infant})`}
                    />

                    <Typography
                        variant={'subtitle1'}
                        color={'secondary'}
                    >
                        {pickupName}({key})
                    </Typography>

                </Box>
                <Divider/>
            </ListSubheader>
            {
                sortedReservations.map((reservation) => {
                    // const timeKey =
                    //     reservation.timestamp !== undefined
                    // ? reservation.timestamp > timestamp ? reservation.timestamp : timestamp
                    // : reservation.snapshotedAt.toString()

                    const timeKey = reservation.snapshotedAt.toString();
                    const key = `${reservation.id}${timeKey}`
                    // console.log(reservation.timestamp, timestamp, reservation.timestamp > timestamp);
                    return (<ReservationItem
                        key={key}
                        reservation={reservation}
                        live={live}
                        isMyTeam={isMyTeam}
                        belongMyTour={belongMyTour}
                        onTimestamp={setTimestamp}
                        productId={productId}
                        teamId={teamId}
                        date={date}
                    />)
                })
            }
        </>
    );
}