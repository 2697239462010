import {getApps, initializeApp} from 'firebase/app';
import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  QuerySnapshot,
  setDoc,
  updateDoc,
  where,
  WhereFilterOp
} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getDownloadURL, getStorage, ref as storageRef, uploadBytesResumable} from 'firebase/storage';
import {getAuth} from 'firebase/auth';
import {DataSnapshot, get, getDatabase, onValue, ref as dbRef, remove, set, update} from 'firebase/database';
import {saveAs} from 'file-saver';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const apps = getApps();
const app = apps.length > 1 ? apps[0] : initializeApp(firebaseConfig);

const auth = getAuth(app);

export function getCurrentUser() {
  return auth.currentUser;
}

const database = getDatabase(app);

export type Query = [string, string, string | number | boolean | string[] | null | any]

export function refWithBaseDatabase(path: string) {
  return dbRef(database, path);
}

export async function readRealtime<S>(path: string): Promise<S> {
  const snapshot = await get(refWithBaseDatabase(path));
  return snapshot.val() as S;
}

export function listenRealtime<S>(path: string, callback: (snapshot: DataSnapshot) => void) {
  const ref = refWithBaseDatabase(path);
  return onValue(ref, callback)
}

export async function removeRealtime(path: string) {
  return await remove(refWithBaseDatabase(path));
}

export async function updateRealtime(path: string, value: any) {
  return await update(refWithBaseDatabase(path), value);
}

export async function setRealtime(path: string, value: any) {
  return await set(refWithBaseDatabase(path), value);
}

const functions = getFunctions(app);

export async function callFunction<S>(name: string, data: unknown) {
  const callable = httpsCallable(functions, name, {timeout: 540000});
  const result = await callable(data);
  return result.data as S;
}

const firestore = getFirestore(app);

export function docWithBaseFireStore(paths: string[]) {
  const [path, ...pathSegments] = paths;
  return doc(firestore, path, ...pathSegments);
}

export function collectionWithBaseFireStore(paths: string[]) {
  const [path, ...pathSegments] = paths;
  return collection(firestore, path, ...pathSegments);
}

export function collectionGroupWithBaseFireStore(path: string) {
  return collectionGroup(firestore, path)
}

export function queryWhereFireStoreGroup(col: string, queries: Query[]) {
  const wheres = queries.map(([property, operator, value]) => where(property, operator as WhereFilterOp, value))
  return query(collectionGroupWithBaseFireStore(col), ...wheres)
}

export function queryNestedFireStoreDoc(
  collection: string, queries: Query[],
  onNext: (snapshot: QuerySnapshot<unknown>) => void,
  onError: (error: Error) => void = console.log
) {
  return onSnapshot(queryWhereFireStoreGroup(collection, queries), onNext, onError);
}


export async function getDocData<T>(paths: string[]): Promise<T> {
  const ref = docWithBaseFireStore(paths);
  const doc = await getDoc(ref);
  return doc.data() as T;
}

export async function setDocData(paths: string[], data: { [key: string]: any }) {
  if (paths.length % 2 === 0) {
    const ref = docWithBaseFireStore(paths);
    await setDoc(ref, data);
    return;
  }

  const ref = collectionWithBaseFireStore(paths);
  return await addDoc(ref, data);
}

export async function updateDocData(paths: string[], data: { [key: string]: any }) {
  const ref = docWithBaseFireStore(paths);
  await updateDoc(ref, data);
}

export function queryWhereFireStore(paths: string[], queries: string[][]) {
  const wheres = queries.map(([property, operator, value]: any) => where(property, operator as WhereFilterOp, value));
  return query(collectionWithBaseFireStore(paths), ...wheres);
}

export function listenWhereFireStore(
  paths: string[],
  queries: any[][],
  onNext: (snapshot: QuerySnapshot<unknown>) => void,
  onError: (error: Error) => void = console.log,
) {
  return onSnapshot(queryWhereFireStore(paths, queries), onNext, onError);
}


const storage = getStorage(app);

export async function uploadFileToStorage(path: string, file: Blob, onProgress: (progress: number, transferredBytes: number, totalBytes: number,) => void) {
  return new Promise<string>((resolve, reject) => {
    const uploadTask = uploadBytesResumable(storageRef(storage, path), file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress, snapshot.bytesTransferred, snapshot.totalBytes);
      },
      (error) => {
        reject(error);
      },
      async () => {
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadUrl);
      },
    );
  });
}

export async function downloadFileFromStorage(path: string) {
  const resp = await fetch(path);
  const blob = await resp.blob();
  let blobUrl = window.URL.createObjectURL(blob);
  saveAs(blobUrl, path.split('token=')[1]);
}
