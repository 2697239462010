import Team from "../../model/Team";
import {v4 as uuidv4} from "uuid";
import Dialog from "@mui/material/Dialog";
import useFListen from "../../controller/firebase/hook/useFListen";
import {setVal, uploadFileToStorage} from "../../controller/firebase";
import {ChangeEvent, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import heic2any from "heic2any";
import useFRead from "../../controller/firebase/hook/useFRead";

export default function (props) {
  const {open, onClose, team} = props;
  const [key, setNewKey] = useState(new Date().toString());
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(team?.bus?.image ?? null);
  const [isUploading, setIsUploading] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [busNumber, setBusNumber] = useState(team?.bus?.number ?? '');

  const {data} = useFRead(team.path);

  useEffect(() => {
    if (data && data.bus) {
      setBusNumber(data.bus?.number);
      setFileUrl(data.bus?.image);
    }
  }, [data]);


  useEffect(() => {
    if (file) {
      const newUrl = URL.createObjectURL(file);
      setFileUrl(newUrl);
      return () => {
        if (newUrl) {
          URL.revokeObjectURL(newUrl);
        }
      }
    } else {
      setFileUrl((prevURL) => {
        if (prevURL) {
          URL.revokeObjectURL(prevURL);
        }
        return null;
      });
    }
  }, [file]);

  async function onUpload() {
    if (!file) return;
    setIsUploading(true);
    const fileUrls = await uploadFile(file);
    setVal(team.path + '/bus/', {number: busNumber, image: fileUrls}).catch(alert);
    setNewKey(new Date().toString());
    setIsUploading(false);
    onClose?.();
  }

  function changeFile(e) {
    if (e.target.files instanceof FileList && e.target.files[0]) {
      const file = e.target.files[0];
      const convert = async (file) => {
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          const uploadable = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 1,
          });
          return Array.isArray(uploadable) ? uploadable[0] : uploadable
        }
        return file;
      }

      convert(file).then((file) => setFile(file))
    }
  }

  async function uploadFile(file) {
    return uploadFileToStorage(`tour/${team?.ids.date}/${team?.ids.tourId}/${team?.ids.teamId}/bus`, file, () => {
    })
        .catch((e) => {
          alert('파일 업로드 중 에러가 발생했습니다.' + `\n${e.toString()}`)
          return '';
        });
  }

  return (
      <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
        <Box pt={3} pb={1} px={2} display={'flex'} flexDirection={'column'} gap={2}>
          <TextField
              fullWidth
              label={'Bus Number'}
              value={busNumber}
              onChange={(e) => {
                setBusNumber(e.target.value)
              }}
          />
          <Button variant={'outlined'} fullWidth component={'label'}>
            {isConverting ? '...Converting' : 'Upload Image'}
            <input
                key={key}
                type="file"
                id="file"
                style={{display: 'none'}}
                accept={'image/*'}
                onChange={changeFile}
            />
          </Button>
          {
              (fileUrl) &&
              <img src={fileUrl} alt="" style={{maxHeight:'280px', objectFit:'contain'}}/>
          }
        </Box>
        <Box py={1} px={1} display={'flex'} gap={1} flexDirection={'column'}>
          <Button variant={'contained'} onClick={onUpload} disabled={isUploading || !(busNumber && file)}>
            {
              isUploading
                  ? 'Uploading'
                  : 'Save'
            }
          </Button>

          <Button variant={'outlined'} onClick={onClose} disabled={isUploading}>
            Close
          </Button>

        </Box>
      </Dialog>

  )
}