import {useMemo} from 'react';
import Box from '@mui/material/Box';
import {useGuideContext} from '../../controller/context/GuideContext';
import formatDash from '../../utils/formatDash';
import WeekBalanceList from './WeekBalanceList';

const aDay = 24 * 60 * 60 * 1000;

export default function BalancePage() {
    const {guide} = useGuideContext();
    const guideFourWeekSchedule = useMemo(() => {
        const weekCount = 4;
        const fourWeeks = Array(weekCount).fill(null).map((_, idx, weeks) => getAWeek(new Date(Date.now() - (7 * (weeks.length - 1 - idx)) * aDay)).map(formatDash));
        return fourWeeks.map((week) => week.map((date) => [date, guide?.schedule?.[date]]));
    }, [guide]);

    return (
        <Box>
            {
                guideFourWeekSchedule.map((week, idx, weeks) => (<WeekBalanceList key={week[0][0]} week={week} defaultExpanded={idx === weeks.length - 1}/>))
            }
        </Box>
    );
}


function getAWeek(baseDate = new Date()) {
    const shift = baseDate.getDay() + 7;
    return Array(7).fill(null)
        .map((_, idx) => new Date(baseDate.getTime() - ((shift % 8) + Math.trunc(shift / 8) - 1 - idx) * aDay));
}

