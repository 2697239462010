import { Box, Badge, Typography, Fab, CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { pink } from '@mui/material/colors';
import ChatIcon from '@mui/icons-material/Chat';
import {IGuide, IParticipant, IUser} from '../../../types/chat';
import { useContext, useState } from 'react';
import { GuideContext } from '../../../controller/context/GuideContext';
import { useNavigate } from 'react-router-dom';
import {parseChatMetadata, parseParticipantData} from '../../../controller/chat/utils';
import { recallChat } from '../../../controller/chat';
import UserAvatar from './UserAvatar';

interface Props {
    participants: IUser[];
    onClick: (user: IUser) => void;
}

export default function ParticipantList({ participants, onClick }: Props) {
    const [loading, setLoading] = useState(false);
    const { guide } = useContext(GuideContext) as any;
    const navigate = useNavigate();
    async function handleOpenChat() {
        const confirm = window.confirm('채팅방을 열겠습니까?');
        if (!confirm) return;

        setLoading(true);
        const category = participants.length > 1 ? 'group' : 'personal';
        const parsedParticipants: IParticipant[] = participants.map((participant) => parseParticipantData(participant)) ;
        const metadata = parseChatMetadata({
            participants: [
                ...parsedParticipants,
                {
                    ...parseParticipantData(guide)
                }
            ],
            category,
            title: category === 'group' ? 'GROUP' : 'PERSONAL',
        });

        const chat = await recallChat({
            category: metadata.category,
            participants: metadata.participants,
            cId: metadata.cId,
            title: metadata.title,
            tour: metadata.tour,
        });
        setLoading(false);
        navigate(`/chat/rooms/${chat.id}`, {
            state: {
                chatMeta: chat,
            },
        });
    }

    return (
        <Box
            sx={{
                position: 'relative',
                visibility: participants.length > 0 ? 'visible' : 'hidden',
            }}
        >
            <Fab
                variant="extended"
                size="small"
                sx={{
                    '&:hover': {
                        color: '#000',
                    },
                    position: 'absolute',
                    top: '-40px',
                    color: '#fff',
                    right: '12px',
                    transition: 'all 0.1s',
                    background: loading ? '#e0e0e0' : '#2196f3',
                }}
                onClick={handleOpenChat}
                disabled={loading}
            >
                {loading && <CircularProgress size={20} sx={{ mr: 1 }} />}
                {!loading && <ChatIcon sx={{ mr: 1 }} />}
                CREATE
            </Fab>
            <Box
                sx={{
                    display: 'flex',
                    columnGap: 1,
                    overflowX: 'auto',
                    p: 1,
                    backgroundColor: '#fff',
                }}
            >
                {participants.map((participant) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            minWidth: '60px',
                            position: 'relative',
                        }}
                    >
                        <Badge
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={
                                <CancelIcon
                                    sx={{
                                        fontSize: '20px',
                                        color: pink[400],
                                        stroke: '#fff',
                                    }}
                                />
                            }
                            onClick={() => onClick(participant)}
                            sx={{
                                cursor: 'pointer',
                                '& .MuiBadge-badge': {
                                    bottom: 5,
                                    right: 5,
                                },
                            }}
                        >
                            <UserAvatar user={participant} />
                        </Badge>
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {participant.name}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
