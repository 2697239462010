import {useLayoutEffect, useState, useCallback, useRef, useEffect} from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import PersonIcon from '@mui/icons-material/Person';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabled from '@mui/icons-material/SyncDisabled';

import Tour from '../../model/Tour';

import useFRead from '../../controller/firebase/hook/useFRead';
import Loading from '../../components/Loading';

import {FilterContext} from '../../controller/context/FilterContext';
import {useDateContext} from '../../controller/context/DateContext';
import {useGuideContext} from '../../controller/context/GuideContext';

import TourStack from './TourStack';
import Notification from './Notification';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {BusAlert, Business, Image} from "@mui/icons-material";
import BusInformation from "./BusInformation";


const operationToTourList = (date) => (operationVal) => {
  if (!operationVal) return [];
  return Object.entries(operationVal).map(([tourId, rawTour]) => new Tour(rawTour, tourId, `/operation/${date}/tours`));
};

export default function TourPage() {
  const {date, dashFormat} = useDateContext();
  const {guide} = useGuideContext();
  const containerRef = useRef(null);
  const [areaOption, setAreaOption] = useState('all');
  const [pickupOption, setPickupOption] = useState('all');
  const [mine, setMine] = useState(true);
  const [defaultLive, setDefaultLive] = useState(false);
  const [useSearch, setUseSearch] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [openBus, setOpenBus] = useState(false);

  const handleToggleBus = () => {
    setOpenBus(prev => !prev);
  }


  const handleAreaOption = useCallback((e) => {
    setAreaOption(e.target.value);
  }, []);

  const handlePickupOption = useCallback((e) => {
    setPickupOption(e.target.value);
  }, []);

  const handleMine = useCallback((e) => {
    setMine(e.target.checked);
  }, []);

  const handleLiveChecked = useCallback((e) => {
    setDefaultLive(e.target.checked);
  }, []);

  const handleToggleSearch = useCallback((e) => {
    setUseSearch((v) => !v);
  }, [setUseSearch]);

  const {
    isLoading: productLoading,
    data: productMap,
  } = useFRead(`/product`, (rawProduct) => new Map(Object.values(rawProduct).map((product) => [product.id, product])), new Map());

  const {
    isLoading,
    data: tours,
    error
  } = useFRead(`/operation/${dashFormat}/tours`, operationToTourList(dashFormat), []);

  const {
    data: notification,
  } = useFRead(`/operation/${dashFormat}/notification`, f => f, null);

  const notifications = Object.entries(notification ?? {})
      .filter(([area, notification]) => (area.toLowerCase() === areaOption || areaOption === 'all'))
      .sort(([area]) => area.toLowerCase() === 'seoul' ? -1 : 0)
      .map(([_, notif]) => notif);


  const {
    filteredOtherTours,
    filteredMyTours
  } = tours
      .filter((t) => !guide.outsource || guide?.managingProducts?.includes(t.productId)) // outsource 필터
      .reduce((result, tour) => {
        if (useSearch && searchKeyword) {
          const tourTeam = Object.values(tour.teams).filter((team) => Object.values(team.reservations).filter(r => r.toString().toLowerCase().includes(searchKeyword.toLowerCase())).length > 0)
          if (tourTeam.length === 0) return result;
        }
        if ((areaOption === 'all' || areaOption === tour.area.toLowerCase())
            && (pickupOption === 'all' || tour.teamInfos.pickupPlaceSet.has(pickupOption))) {
          if (tour.teamInfos.guideSet.has(guide.id)) {
            result.filteredMyTours.push(tour);
          } else {
            result.filteredOtherTours.push(tour);
          }
        }
        return result;
      }, {filteredOtherTours: [], filteredMyTours: []});

  const filteredTours = (guide?.admin || guide?.outsource || guide?.cs || filteredMyTours.length) ? filteredMyTours.concat(filteredOtherTours) : [];
  const myTour = filteredMyTours[0]
  const myTourProduct = myTour ?  productMap.get(myTour?.id) : undefined;
  const myTeam = myTour?.teamList.find((team) => team.guides.includes(guide.id));
  const myPickups = [...new Set(Object.values(myTeam?.reservations ?? {}).map((r) => r.pickupPlace)).values()].map((p) => {
    return myTourProduct?.chat?.pickup?.[p];
  }).filter(a => !!a);
  const myPickupTimes = myPickups.map((pickup)=>{
    const time = pickup.time ?? '10:00';
    const date = dashFormat;
    return new Date(`${date}T${time}+09:00`);
  }).sort((a,b)=>a.getTime() - b.getTime());
  const lastPickupTimes = myPickupTimes.at(-1);
  const isUsingKup = myTeam?.path.includes('Seoul')
  const displayBus = lastPickupTimes &&  (lastPickupTimes.getTime() - 5 * 60 * 1000 < Date.now());

  useLayoutEffect(() => {
    //todo busan 참조
    const defaultAreaOption = (guide.outsource || guide.cs || guide.admin)
        ? 'all'
        : guide?.area.toLowerCase().includes('busan')
            ? 'busan'
            : guide?.area.toLowerCase().includes('tokyo')
                ? 'tokyo'
                : 'seoul';
    if (defaultAreaOption !== areaOption)
      setAreaOption(defaultAreaOption);
    if ((guide.admin || guide.cs || guide.outsource) && mine) {
      setMine(false);
    }
  }, [guide?.name, guide?.level]);


  return (
      <>
        <Box
            ref={containerRef}
        >
          <Box
              mb={'72px'}
          >
            {
                (mine && myTeam && isUsingKup && displayBus) && (
                    <Box py={1} px={1}
                         display={'flex'}
                         flexDirection={'row'}
                         justifyContent={'flex-end'}
                    >
                      <Button
                          fullWidth
                          endIcon={<BusAlert/>} variant={'outlined'} onClick={handleToggleBus}>
                        Bus Information
                      </Button>
                    </Box>
                )
            }
            <Box display={'flex'}>
              <Checkbox
                  sx={{
                    py: 0,
                    px: 1
                  }}
                  icon={<PersonOutlinedIcon/>}
                  checkedIcon={<PersonIcon/>}
                  checked={mine}
                  onChange={handleMine}
              />


              <Checkbox
                  sx={{
                    py: 0,
                    px: 1
                  }}
                  icon={<SyncDisabled/>}
                  checkedIcon={<SyncIcon/>}
                  checked={defaultLive}
                  onChange={handleLiveChecked}
              />

              <Grid container>
                <Grid item xs={6}>
                  <Select
                      fullWidth
                      disabled={!guide.admin && !guide.cs}
                      variant={'standard'}
                      value={areaOption}
                      onChange={handleAreaOption}
                      sx={{
                        textAlign: 'center',
                        fontWeight: '500',
                        '&::before': {
                          display: 'none'
                        },
                        '& .MuiSelect-select': {
                          minHeight: '36px !important',
                          lineHeight: '36px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          p: 0,
                        }
                      }}
                  >
                    {
                      (guide.outsource || guide.cs || guide.admin)
                          ? (
                              <MenuItem value={'all'}>
                                전체(ALL)
                              </MenuItem>
                          )
                          : null
                    }
                    {
                      Object.entries(Tour.areaName).map(([value, name]) => (
                          <MenuItem key={value} value={value}>{name}({value.toUpperCase()})</MenuItem>
                      ))
                    }
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                      fullWidth
                      variant={'standard'}
                      value={pickupOption}
                      onChange={handlePickupOption}
                      sx={{
                        textAlign: 'center',
                        fontWeight: '500',
                        '&::before': {
                          display: 'none'
                        },
                        '& .MuiSelect-select': {
                          minHeight: '36px !important',
                          lineHeight: '36px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          p: 0,
                        }
                      }}
                  >
                    <MenuItem value={'all'}>
                      전체(ALL)
                    </MenuItem>
                    {
                      Object.entries(Tour.areaPickupName[areaOption] ?? {}).map(([value, name]) => (
                          <MenuItem key={value} value={value}>{name}({value.toUpperCase()})</MenuItem>
                      ))
                    }
                  </Select>
                </Grid>
              </Grid>
              <IconButton
                  onClick={handleToggleSearch}
              >
                {
                  useSearch
                      ? <CloseIcon/>
                      : <SearchIcon/>
                }

              </IconButton>
            </Box>
            {
              useSearch
                  ? (<Box
                      px={2}
                      pt={1}
                  >
                    <TextField
                        focused
                        autoFocus
                        fullWidth
                        variant={'standard'}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        placeholder={'이름(Name), 예약번호(reservation No), 전화번호(tel), 이메일(email) ...'}
                        value={searchKeyword}
                    />
                  </Box>)
                  : null
            }
            {
              isLoading
                  ? <Loading/>
                  : <FilterContext.Provider value={{areaOption, pickupOption, mine, defaultLive}}>
                    <TourStack
                        searchKeyword={useSearch ? searchKeyword : ''}
                        tours={
                          mine
                              ? filteredMyTours
                              : filteredTours
                        }
                        productMap={productMap}
                    />
                  </FilterContext.Provider>

            }
          </Box>
        </Box>
        <Notification
            myTeam={myTeam}
            guide={guide}
            notifications={notifications}
        />
        {
            (myTeam && openBus && isUsingKup) &&
            <BusInformation open={myTeam && openBus} onClose={handleToggleBus} team={myTeam}/>
        }
      </>
  );
}


