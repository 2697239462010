import {useEffect, useState} from 'react';
import {readWithChildValue} from '../index';

export default function useFReadWithChildVals(path, child, childVals, format = f => f) {
    const [data, setData] = useState(Object.fromEntries(childVals.map(val => [val, null])));
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        const handler = (val) => (snapshot) => {
            setData((exists) => ({...exists, [val]: format(snapshot.val())}));
        };
        const errorHandler = (e) => {
            setError(e);
        };
        const loaded = () => {
            setIsLoading(false);
        };
        (async () => {
            for (let childVal of childVals) {
                handler(childVal)(await readWithChildValue(path, child, childVal));
            }
        })().catch(errorHandler)
            .finally(loaded);

    }, [path]);
    return {
        isLoading,
        data,
        error
    };
}