// @flow
import * as React from "react";
import { Box } from "@mui/material";

type Props = {
  width?:
    | {
        mobile: number;
        desktop: number;
      }
    | number;
  height?:
    | {
        mobile: number;
        desktop: number;
      }
    | number;
};

export function Space({ width, height }: Props) {
  let widthValue;
  let heightValue;

  if (typeof width === "object") {
    widthValue = {
      md: `${width.desktop}px`,
      xs: `${width.mobile}px`,
    };
  } else {
    widthValue = `${width}px`;
  }

  if (typeof height === "object") {
    heightValue = {
      md: `${height.desktop}px`,
      xs: `${height.mobile}px`,
    };
  } else {
    heightValue = `${height}px`;
  }

  return (
    <Box
      sx={{
        width: widthValue,
        height: heightValue,
      }}
    />
  );
}
