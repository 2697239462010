import getFlag from '../utils/getFlag';

export default class Reservation {
    constructor(rawReservation, reservationId, basePath) {
        this._ = rawReservation;
        this.id = reservationId;
        this.path = `${basePath}/${reservationId}`;
        this.clientName = rawReservation.clientName;
        this.people = rawReservation.adult + rawReservation.kid + rawReservation.infant;
        this.adult = rawReservation.adult;
        this.kid = rawReservation.kid;
        this.infant = rawReservation.infant;
        this.agency = rawReservation.agency;
        this.agencyCode = rawReservation.agencyCode;
        this.pickupPlace = rawReservation.pickupPlace;
        this.email = rawReservation.email;
        this.tel = rawReservation.tel;
        this.messenger = rawReservation.messenger;
        this.memo = rawReservation.memo;
        this.nationality = rawReservation.nationality;
        this.language = rawReservation.language.toUpperCase().replace(/-GUIDE/gi, '') ;
        this.check = rawReservation.check;
        this.noShow = typeof rawReservation.noShow === 'string' ? JSON.parse(rawReservation.noShow) : rawReservation.noShow;
        this.option = Object.values(rawReservation.option ?? []);
        this.snapshotedAt = new Date();
        this.stroller = rawReservation.stroller;
        this.timestamp = rawReservation.timestamp !== undefined ? new Date(rawReservation.timestamp).getTime() : undefined;
        this.kup = rawReservation.kup ?? false;
    }

    get nationalityFlag() {
        return getFlag(this.nationality);
    }

    toString(){
        return `${this.agency}${this.agencyCode}${this.clientName}${this.email}${this.tel}${this.messenger}${this.memo}`
    }
}

