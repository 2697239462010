import {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetail from '@mui/material/AccordionDetails';

import Checkbox from '@mui/material/Checkbox';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabled from '@mui/icons-material/SyncDisabled';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';

import useFListen from '../../controller/firebase/hook/useFListen';
import useFRead from "../../controller/firebase/hook/useFRead";

import {useFilterContext} from '../../controller/context/FilterContext';
import {useGuideContext} from '../../controller/context/GuideContext';
import {useDateContext} from '../../controller/context/DateContext';

import Tour from '../../model/Tour';
import TeamPart from './TeamPart';
import {callFunction} from '../../controller/firebase';
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import {Autocomplete, Card, CardActions, CardContent, Paper} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const tourValtoTour = (basePath) => (tourVal, tourId, snapshot) => {
    if (!tourVal) return {};
    return new Tour(tourVal, tourId, basePath);
};

export default function TourAccordion({defaultTour, searchKeyword, productMap}) {
    const navigate = useNavigate();

    const {guide, guideMap} = useGuideContext();
    const isMyTour = defaultTour?.teamInfos.guideSet.has(guide?.id) ?? false;

    const {date, dashFormat, setDate} = useDateContext();
    const {pickupOption, defaultLive, mine} = useFilterContext();

    const [live, setLive] = useState(defaultLive || isMyTour || (guide.outsource) && guide.managingProducts?.includes(defaultTour.productId));
    const [loadingCreateChat, setLoadingCreateChat] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [openModal, setOpenModal] = useState(false);


    const {data: tour} = useFListen(live, defaultTour.path, tourValtoTour(defaultTour.basePath), defaultTour);
    const isOutsourced = live && (guide.outsource) && guide.managingProducts?.includes((tour ?? defaultTour).productId);

    const handleLiveChecked = useCallback((e, value) => {
        setLive(e.target.checked);
    }, []);
    const stopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);

    const {product, productId, teams = {}} = tour ?? defaultTour;
    const teamList = Object.values(teams);
    const guides = teamList
        .map((t) => t.guides)
        .flat(1)
        .filter((g) => !!g)
        .map((gId) => {
            const guide = guideMap?.get(gId);
            return guide ? {name: guide.name, id: guide.id, nameEn: guide.nameEn, type: 'Guide'} : null;
        })
        .filter((g) => !!g);


    const handleToggleModal = (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (!live) {
            alert('실시간 동기화(Sync)를 켜주세요');
            return;
        }

        setOpenModal((open) => !open);
    }


    return (
        <>
            <Accordion
                TransitionProps={{
                    timeout: 0,
                }}
                sx={{
                    margin: '0px !important',
                    boxShadow: 'none',
                    '&:before': {
                        display: 'none',
                    },
                }}
                defaultExpanded={mine && isMyTour}
                defaultChecked={mine && isMyTour}
            >
                <AccordionSummary
                    sx={{
                        position: 'sticky',
                        top: '56px',
                        left: 'auto',
                        zIndex: 1000,
                        background: 'white',
                        minHeight: '56px !important',
                        '& .MuiAccordionSummary-content': {
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0px !important',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            marginRight: -0.5,
                        },
                    }}
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <Checkbox
                        sx={
                            {
                                // marginLeft:'-14px', //edge start adjust
                            }
                        }
                        size={'small'}
                        edge={'start'}
                        icon={<SyncDisabled/>}
                        checkedIcon={<SyncIcon/>}
                        checked={live || isMyTour}
                        disabled={isMyTour}
                        onChange={handleLiveChecked}
                        onClick={stopPropagation}
                    />
                    <Typography
                        variant={'h6'}
                        sx={(theme) => ({
                            color: isMyTour ? theme.palette.primary.main : theme.palette.text.primary,
                            fontSize: '1.2rem',
                        })}
                    >
                        {productMap?.get(tour.productId)?.name ?? tour.product}
                    </Typography>
                    {
                        isOutsourced
                            ? (
                                <IconButton disabled={!isOutsourced}>
                                    <AddIcon onClick={handleToggleModal}/>
                                </IconButton>
                            )
                            : null
                    }
                    {/*{guide.admin || isMyTour ? (*/}
                    {/*    <IconButton*/}
                    {/*        onClick={(e) => {*/}
                    {/*            e.stopPropagation();*/}
                    {/*            e.preventDefault();*/}

                    {/*            if (!guides || guides.length === 0) return;*/}
                    {/*            if (!window?.confirm('채팅을 여시겠습니까?')) return;*/}
                    {/*            async function recallChat() {*/}
                    {/*                setLoadingCreateChat(true);*/}
                    {/*                if (chatId) return chatId;*/}
                    {/*                const chat = await callFunction('recallChat', {*/}
                    {/*                    category: 'TOUR',*/}
                    {/*                    cId: `${dashFormat}:${productId}`,*/}
                    {/*                    title: `${dashFormat}:${product}`,*/}
                    {/*                    participants: [*/}
                    {/*                        ...guides,*/}
                    {/*                        ...OPERATOR,*/}
                    {/*                        ...(productId.toLowerCase().includes('ski') ? ACCOUNTANTS : []),*/}
                    {/*                        {*/}
                    {/*                            id: guide?.uid,*/}
                    {/*                            name: guide?.name,*/}
                    {/*                            nameEn: guide?.nameEn,*/}
                    {/*                            type: guide?.level > 1 ? 'Guide' : 'Operator',*/}
                    {/*                        },*/}
                    {/*                    ],*/}
                    {/*                    tour: {*/}
                    {/*                        date: dashFormat,*/}
                    {/*                        productId: productId,*/}
                    {/*                        team: null,*/}
                    {/*                    },*/}
                    {/*                });*/}
                    {/*                if (chat && chat.id) {*/}
                    {/*                    setChatId(chat.id);*/}
                    {/*                }*/}
                    {/*                return chat.id;*/}
                    {/*            }*/}

                    {/*            recallChat()*/}
                    {/*                .then((chatId) => {*/}
                    {/*                    try {*/}
                    {/*                        if (!chatId) return alert('적절한 채팅방을 찾을 수 없습니다.');*/}
                    {/*                        navigate(`/chat/rooms/${chatId}`);*/}
                    {/*                    } catch (e) {*/}
                    {/*                        alert('채팅방을 열 수 없습니다.');*/}
                    {/*                    }*/}
                    {/*                })*/}
                    {/*                .catch(console.error)*/}
                    {/*                .finally(() => {*/}
                    {/*                    setLoadingCreateChat(false);*/}
                    {/*                });*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        {loadingCreateChat ? (*/}
                    {/*            <CircularProgress*/}
                    {/*                size={'small'}*/}
                    {/*                sx={{*/}
                    {/*                    width: '20px',*/}
                    {/*                    height: '20px',*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        ) : (*/}
                    {/*            <ChatIcon*/}
                    {/*                sx={{*/}
                    {/*                    width: '20px',*/}
                    {/*                    height: '20px',*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    </IconButton>*/}
                    {/*) : null}*/}
                </AccordionSummary>

                <AccordionDetail
                    sx={{
                        p: 0,
                    }}
                >
                    <List disablePadding>
                        {tour.teamList
                            .filter(
                                (team) =>
                                    (mine ? team.guides.includes(guide.id) : true) &&
                                    (pickupOption === 'all' ||
                                        team.pickupPlaces.map((p) => p.key.toLowerCase()).includes(pickupOption)) &&
                                    (!searchKeyword ||
                                        Object.values(team.reservations).filter((r) =>
                                            r.toString().toLowerCase().includes(searchKeyword.toLowerCase()),
                                        ).length > 0),
                            )
                            .map((team, idx) => {
                                return (
                                    <TeamPart
                                        idx={idx}
                                        product={product}
                                        productId={productId}
                                        key={team.id}
                                        team={team}
                                        live={live}
                                        isMyTour={isMyTour}
                                        searchKeyword={searchKeyword}
                                    />
                                );
                            })}
                    </List>
                </AccordionDetail>
            </Accordion>
            {
                openModal
                    ? (<CreateOutsourceReservationModal
                        open={openModal}
                        onClose={handleToggleModal}
                        date={dashFormat}
                        tour={tour}
                    />)
                    : null
            }
        </>
    );
}


function CreateOutsourceReservationModal(props) {
    const {date, tour, open, onClose} = props;
    const {data: product} = useFRead(`product/${tour.productId}`);
    const {data: nationalities} = useFRead(`nationality`);
    const {data: pickups} = useFRead(`pickup`);
    const [loading, setLoading] = useState(false);

    // callFunction('createOutsourcedReservation', {area: product?.area ?? 'seoul'})


    const pickupPlaceOptions = Object.entries(pickups?.[(product?.area ?? 'seoul').toLowerCase()] ?? {})
        .sort((a, b) => a[1].order - b[1].order)
        .map(([key, pickup]) => {
            return ({
                id: pickup.place,
                label: pickup.place,
                name: pickup.place,
                value: pickup.place,
            })
        })

    const nationalityOptions = Object.values(nationalities ?? {}).map(n => ({
        id: n.place,
        name: n.place,
        label: n.place,
        value: n.place,
    }))

    const teamOptions = Object.values(tour.teams ?? {}).map((team, idx) => ({
        id: team.id,
        name: team._.guides ? team._.guides.map((g) => g.name).join(', ') : `TEAM - ${idx + 1}`,
        label: team._.guides ? team._.guides.map((g) => g.name).join(', ') : `TEAM - ${idx + 1}`,
        value: team.id,
    }));


    const [tempReservation, setTempReservation] = useState({
        date,
        agency: 'KTOS',
        product: tour.product,
        productId: tour.productId,
        tourId: tour.id,
        teamId: Object.keys(tour.teams ?? {})[0] ?? '',
        pickupPlace: '',
        pickupTime: '',
        clientName: '',
        adult: 1,
        kid: 0,
        infant: 0,
        tel: '',
        messenger: '',
        email: '',
        nationality: '',
        memo: '',
        language: 'English',
        option: [],
    })

    const options = (product?.option ?? []).filter((option) => {
        // return !(reservation?.option.map((o) => o.option).concat(['Ignore']).includes(option.option));
        return !['Ignore'].includes(option.option);
    }).map((option) => ({
        option: option.option,
        people: tempReservation?.option.find((o) => o.option === option.option)?.people ?? 0
    }));


    const handleCreate = () => {
        setLoading(true)
        callFunction('createOutsourcedReservation', {reservation: tempReservation})
            .then(() => {
                onClose();
                alert("에약이 생성되었습니다.");
            })
            .catch((e) => {
                alert("에약이 생성에 실패했습니다. \r\n" + e.toString());
            })
            .finally(() => {
                setLoading(false)
            })
    }


    const isUpdatable = !!tempReservation.pickupPlace && !!tempReservation.teamId && !!tempReservation.clientName && !!tempReservation.adult;

    return <Modal open={open} onClose={onClose}>
        <Card
            sx={{
                margin: '20px 8px',
                maxHeight: 'calc(100vh - 100px)',
                overflowY: 'scroll'
            }}
        >
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <TextField fullWidth disabled label={'Date'}
                                   value={tempReservation.date}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <TextField fullWidth disabled label={'Product'}
                                   value={tempReservation.product}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={pickupPlaceOptions}
                            getOptionLabel={(option) => option.name}
                            value={{
                                id: tempReservation?.pickupPlace ?? '',
                                name: tempReservation?.pickupPlace ?? ''
                            }}
                            onChange={(_, pickupOption) => {
                                if (pickupOption) {
                                    setTempReservation({
                                        ...tempReservation,
                                        pickupPlace: pickupOption.id,
                                    });
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={'PickupPlace'}
                                    InputLabelProps={{shrink: true}}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={teamOptions}
                            getOptionLabel={(option) => option.name}
                            value={(()=>{
                                const teamId = tempReservation?.teamId;
                                return teamOptions.find((o) => o.id === teamId);
                            })()}
                            onChange={(_, nationalityOption) => {
                                if (nationalityOption) {
                                    setTempReservation({
                                        ...tempReservation,
                                        teamId: nationalityOption.id,
                                    });
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={'Team(Guide)'}
                                    InputLabelProps={{shrink: true}}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box py={1}>
                            <Divider/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label={'Client Name'}
                                   value={tempReservation.clientName}
                                   onChange={(e) => {
                                       setTempReservation({
                                           ...tempReservation,
                                           clientName: e.target.value,
                                       });
                                   }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField fullWidth type={'number'} label={'Adult'}
                                   value={tempReservation.adult}
                                   onChange={(e) => {
                                       const number = Number.parseInt(e.target.value);
                                       const adult = number;
                                       setTempReservation({
                                           ...tempReservation,
                                           adult: adult,
                                       });
                                   }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField fullWidth type={'number'} label={'Kid'}
                                   value={tempReservation.kid}
                                   onChange={(event) => {
                                       const number = Number.parseInt(event.target.value);
                                       const kid = number;
                                       setTempReservation({
                                           ...tempReservation,
                                           kid: kid,
                                       });
                                   }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField fullWidth type={'number'} label={'Infant'}
                                   value={tempReservation.infant}
                                   onChange={(event) => {
                                       const number = Number.parseInt(event.target.value);
                                       const infant = number;
                                       setTempReservation({
                                           ...tempReservation,
                                           infant: infant,
                                       });
                                   }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box py={1}>
                            <Divider/>
                        </Box>
                    </Grid>
                    {
                        options.map((option, idx) => (
                            <>
                                {
                                    idx > 0
                                        ? (
                                            <Grid
                                                item
                                                xs={12}
                                                md={2}
                                            >
                                            </Grid>
                                        )
                                        : null
                                }
                                <Grid
                                    item
                                    xs={8}
                                    key={option.option}
                                >
                                    <TextField
                                        fullWidth
                                        disabled
                                        label={'Option'}
                                        value={option.option}
                                        InputLabelProps={{shrink: true}}
                                        sx={{backgroundColor: 'white'}}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    key={option.option + option.people}
                                >
                                    <TextField
                                        fullWidth
                                        type={'number'}
                                        label={'People'}
                                        value={option.people}
                                        InputLabelProps={{shrink: true}}
                                        sx={{backgroundColor: 'white'}}
                                        onChange={(e) => {
                                            const value = Number.parseInt(e.target.value ? e.target.value : '0');
                                            if (Number.isNaN(value)) return;
                                            const reservationOptions = tempReservation?.option ?? []
                                            const scope = reservationOptions.find((o) => {
                                                return o.option === option.option
                                            });
                                            if (scope) {
                                                scope.people = value;
                                            } else {
                                                reservationOptions.push({
                                                    option: option.option,
                                                    people: value
                                                });
                                            }
                                            const filtered = reservationOptions.filter((o) => o.people)
                                            setTempReservation({
                                                ...tempReservation,
                                                option: filtered
                                            })
                                        }}
                                    />
                                </Grid>
                            </>
                        ))
                    }
                    <Grid item xs={12}>
                        <Box py={1}>
                            <Divider/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={nationalityOptions}
                            getOptionLabel={(option) => option.name}
                            value={{
                                id: tempReservation?.nationality ?? '',
                                name: tempReservation?.nationality ?? ''
                            }}
                            onChange={(_, nationalityOption) => {
                                if (nationalityOption) {
                                    setTempReservation({
                                        ...tempReservation,
                                        nationality: nationalityOption.id,
                                    });
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={'Nationality'}
                                    InputLabelProps={{shrink: true}}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            options={[
                                {id: 'English', name: 'English'},
                                {id: 'Chinese', name: 'Chinese'},
                                {id: 'Japanese', name: 'Japanese'},
                                {id: 'Korean', name: 'Korean'}]
                            }
                            value={
                                (tempReservation?.language).split(',').map(s => s.trim()).filter(s => !!s)
                                    .map((language) => ({
                                        id: language, name: language
                                    }))
                            }
                            onChange={(_, languageOption) => {
                                if (languageOption.filter(o => !!o?.id).length) {
                                    setTempReservation({
                                        ...tempReservation,
                                        language: languageOption.map((o) => o.id).join(', '),
                                    });
                                } else {
                                    setTempReservation({
                                        ...tempReservation,
                                        language: "",
                                    });
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.id.toUpperCase() === value.id.toUpperCase()}
                            getOptionLabel={(option) => `${option.id}`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={'Lanuage'}
                                    InputLabelProps={{shrink: true}}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box py={1}>
                            <Divider/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label={'Tel'}
                                   onChange={(event) => {
                                       setTempReservation({
                                           ...tempReservation,
                                           tel: event.target.value,
                                       });
                                   }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label={'Messenger'}
                                   onChange={(event) => {
                                       setTempReservation({
                                           ...tempReservation,
                                           messenger: event.target.value,
                                       });
                                   }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label={'Email'}
                                   onChange={(event) => {
                                       setTempReservation({
                                           ...tempReservation,
                                           email: event.target.value,
                                       });
                                   }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box py={1}>
                            <Divider/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            label={'Memo'}
                            onChange={(event) => {
                                setTempReservation({
                                    ...tempReservation,
                                    memo: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions sx={{
                backgroundColor: 'white',
                position: 'sticky',
                bottom: 0,
                left: 0,
                padding: 2,
                margin: 0,
                zIndex: 1,
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Button fullWidth onClick={onClose}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Button fullWidth
                                disabled={!isUpdatable || loading}
                                variant={'contained'}
                                onClick={handleCreate}>
                            {
                                loading
                                    ? <CircularProgress size={24}/>
                                    : 'CREATE'
                            }
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    </Modal>
}