import {IChatMeta, ICreateChatParams, IGuide, IParticipant, ISender, ITour, IUser} from '../../types/chat';

import {
    QueryDocumentSnapshot,
    QuerySnapshot,
    Timestamp,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where, getDoc,
    getCountFromServer, collection,
    serverTimestamp
} from 'firebase/firestore';
import {
    callFunction,
    collectionWithBaseFireStore, docWithBaseFireStore,
    getDocData, listenRealtime,
    listenWhereFireStore, queryNestedFireStoreDoc,
    setDocData,
    updateDocData,
} from './firebase';
import {it} from "node:test";
import {DataSnapshot, ref} from "firebase/database";

//todo indexedDB
// import { deleteTxFromIndexedDB } from './indexedDB';

export const recallChat = async ({
                                     category,
                                     participants,
                                     cId,
                                     title,
                                     tour,
                                 }: ICreateChatParams): Promise<IChatMeta> => {
    const chat: IChatMeta = await callFunction('recallChat', {
        category,
        participants,
        cId,
        title,
        tour,
    });

    return chat;
};

export const getParticipants = async (chatId: string): Promise<{ [id: string]: IParticipant }> => {
    const {participants} = (await getDocData(['chats', chatId])) as IChatMeta;
    return participants;
};
export const updateReadStatusMessage = async (chatId: string, uid: string) => {
    const ref = collectionWithBaseFireStore(['chats', chatId, 'messages']);
    const querySnapshot = await getDocs(query(ref, where('readStatus.' + uid, '==', false)));

    querySnapshot.forEach((doc: QueryDocumentSnapshot<unknown>) => {
        updateDoc(doc.ref, {[`readStatus.${uid}`]: true});
    });

    const chatRef = docWithBaseFireStore(['chats', chatId]);
    const readAt = new Date().toString();
    updateDoc(chatRef, {[`readDate.${uid}`]: readAt}).catch(console.error);
};

export const sendMessage = async (
    chatId: string,
    participants: IParticipant[],
    sender: Pick<IGuide, 'id' | 'name' | 'nameEn' | 'level' | 'raw'>,
    message: {
        type: string;
        text?: string;
        files?: string[];
    } = {
        type: 'text',
        text: '',
        files: [],
    },
    reply?: {
        id: string;
        text: string;
    },
) => {
    let data = {};
    const readStatus = participants
        .map((p) => p.id)
        .reduce((r: any, p: any) => {
            return {
                ...r,
                [p]: p === sender.id,
            };
        }, {});
    switch (message.type) {
        case 'text': {
            data = {
                text: message.text,
                readStatus,
            };
            break;
        }
        case 'reply': {
            data = {
                text: message.text,
                reply,
                readStatus,
            };
            break;
        }
        case 'image': {
            data = {
                files: message.files,
                readStatus,
            };
            break;
        }
        case 'exit': {
            data = {
                text: `${sender.name} left the room`,
            };
            break;
        }
        case 'enter': {
            if (!Array.isArray(participants) || participants.length === 0) return;
            const text =
                participants.length > 1
                    ? `${participants.at(0)?.nameEn} and ${participants.length - 1} others have entered the room`
                    : `${participants?.at(0)?.nameEn} entered the room`;
            data = {
                text,
            };
            break;
        }
    }
    await setDocData(['chats', chatId, 'messages'], {
        type: message.type,
        date: serverTimestamp(), //Timestamp.now(),
        sender: {
            type: sender.level >= 1 ? 'guide' : 'operator',
            name: sender.name,
            nameEn: sender.nameEn,
            id: sender.id,
            photoURL: JSON.parse(sender.raw).photoURL ?? '',
        },
        ...data,
    });

    await updateDocData(['chats', chatId], {
        updatedAt: serverTimestamp(),
    });
};

export const deleteChat = async (chatId: string, user: IUser) => {
    await updateReadStatusMessage(chatId, user.id);
    await callFunction('deleteChat', {docId: chatId, uid: user.id});
    const message = {
        type: 'exit',
    };

    await sendMessage(chatId, [], user, message);
};

export const countChatMessages = async (chatId: string): Promise<number> => {
    const countSnapshot = await getCountFromServer(collectionWithBaseFireStore(['chats', chatId, 'messages']));
    return countSnapshot.data().count ?? 0;
}


export const subscribeToTeamOfChat = async (chatId: string, callback: (snapshot: DataSnapshot) => void) => {
    const {tour} = (await getDocData(['chats', chatId])) as IChatMeta;
    if (!tour) return () => {
    }
    const iTour = tour as ITour;
    const date = iTour.date;
    const tourId = iTour.tourId || iTour.productId;
    const teamId = iTour.teamId || iTour.team;
    if (!date || !tourId || !teamId) return () => {
    }
    return subscribeToTeam(date, tourId, teamId, callback);
}

export const subscribeToTeam = (date: string, tourId: string, teamId: string, callback: (snapshot: DataSnapshot) => void) => {
    return listenRealtime(`/operation/${date}/tours/${tourId}/teams/${teamId}`, callback);
}


export const subscribeToOwnChats = (uid: string, date: string, collectThrough: string, callback: (snapshot: QuerySnapshot<any>) => void, errorCallback?: (error: Error) => void) => {

    const queries: any[][] = [
        [`participants.${uid}.id`, '==', uid],
        [collectThrough, '==', date]
    ];
    return listenWhereFireStore(['chats'],
        queries, callback, errorCallback);
};

export const subscribeToUnreadMessages = (
    chatId: string,
    uid: string,
    callback: (snapshot: QuerySnapshot<any>) => void,
) => {
    return listenWhereFireStore(['chats', chatId, 'messages'], [[`readStatus.${uid}`, '==', false]], callback);
};

export const subscribeToLastMessage = (chatId: string, callback: (snapshot: QuerySnapshot<any>) => void) => {
    return onSnapshot(
        query(collectionWithBaseFireStore(['chats', chatId, 'messages']), orderBy('date', 'desc'), limit(1)),
        callback,
    );
};

export const subscribeToNewMessages = (
    chatId: string,
    lastMessageDate: Date,
    callback: (snapshot: QuerySnapshot<any>) => void,
) => {
    return onSnapshot(
        query(
            collectionWithBaseFireStore(['chats', chatId, 'messages']),
            orderBy('date', 'asc'),
            where('date', '>', lastMessageDate),
        ),
        callback,
    );
};

export const subscribeToAllUnreadMessages = (participantId: string, callback: (snapshot: QuerySnapshot<any>) => void, callbackFullList?: (chatIdList: string[]) => void) => {
    return queryNestedFireStoreDoc('messages', [[`readStatus.${participantId}`, '==', false]], (querySnapshot) => {
        const _chatIds: string[] = [];
        querySnapshot.forEach(async (doc) => {
            if (doc.ref.parent.parent?.id) {
                _chatIds.push(doc.ref.parent.parent?.id);
            }
        })
        const chatIdSet = new Set(_chatIds);
        const chatIdList = [...chatIdSet.values()];
        callbackFullList?.(chatIdList);
        if (chatIdList.length > 0) {
            const slicedChatIdList = chatIdList.slice(0, 30);
            const ref = collectionWithBaseFireStore(['chats'])
            getDocs(query(ref, where('id', 'in', slicedChatIdList))).then(callback)
        }
    });
}

export const subscribeToAllUnreadMessageChatList = (participantId: string, callback: (chatIdList: string[]) => void) => {
    return queryNestedFireStoreDoc('messages', [[`readStatus.${participantId}`, '==', false]], (querySnapshot) => {
        const _chatIds: string[] = [];
        querySnapshot.forEach(async (doc) => {
            if (doc.ref.parent.parent?.id) {
                _chatIds.push(doc.ref.parent.parent?.id);
            }
        })
        const chatIdSet = new Set(_chatIds);
        const chatIdList = [...chatIdSet.values()];
        const ref = collectionWithBaseFireStore(['chats'])
        callback(chatIdList);
    });
}