import {Box, Typography} from '@mui/material';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {IMessage, IParticipant, ISender} from '../../types/chat';
import {subscribeToNewMessages, updateReadStatusMessage} from '../../controller/chat';
import {Messages} from '../../components/chat/chat/Messages';
import ChatHeader from '../../components/chat/chat/ChatHeader';
import MessageForm from '../../components/chat/chat/MessageForm';
import {GuideContext} from '../../controller/context/GuideContext';
import {isParticipant, separateMessages} from '../../controller/chat/utils';
import {useChatContext} from '../../context/ChatContext';
import CloseIcon from '@mui/icons-material/Close';

//todo indexedDB
// import {getMessagesFromIndexedDB, openIndexedDB, saveMessagesToIndexedDB} from '../../controller/chat/indexedDB';

export default function Chat() {
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [rawMessages, setRawMessages] = useState<IMessage[]>([]);
  const memoMessages = useMemo(() => separateMessages(rawMessages), [rawMessages]);
  const messagesRef = useRef<HTMLInputElement>(null);
  const {state: chatState, dispatch: chatDispatch} = useChatContext();

  const params = useParams();
  const navigate = useNavigate();
  const {guide} = useContext(GuideContext) as any;
  const {chatId} = params as { chatId: string };

  useEffect(() => {
    async function init() {
      const {isExist, participants} = await isParticipant(chatId, guide.id);
      if (!isExist || !participants) {
        alert('You are not a participant of this chat');
        navigate('/chat');
        return;
      }
      setParticipants(Object.values(participants));
      setRawMessages([]);
    }

    init();
  }, [chatId]);

  useEffect(() => {
    async function refresh() {
      const {isExist, participants} = await isParticipant(chatId, guide.id);
      if (!isExist || !participants) {
        alert('You are not a participant of this chat');
        navigate('/chat');
        return;
      }
      setParticipants(Object.values(participants));
    }
    refresh();
  }, [rawMessages.length]);

  useEffect(() => {
    const unsub = subscribeToNewMessages(chatId, new Date(0), (querySnapshot) => {
      const messages = querySnapshot.docs.map(
          (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              }) as IMessage,
      )
      setRawMessages(messages);
    });

    return () => {
      if (unsub) unsub();
    };

    // let indexedDB: any = null;
    // let unsub: any = null;
    // let oldMessages: any[] = [];
    // let newMessages: any[] = [];
    // const lastMessageDate = new Date(0);

    // openIndexedDB()
    //     .then((openDB) => {
    //         indexedDB = openDB;
    //         return getMessagesFromIndexedDB(indexedDB, chatId);
    //     })
    //     .then((messages) => {
    //         const lastMessage = messages.length > 0 ?  messages[messages.length -1] : null;
    //         oldMessages = messages;
    //         const lastMessageDate = lastMessage ? new Date(lastMessage.date.seconds * 1000) : new Date(0);
    //         unsub = subscribeToNewMessages(chatId, lastMessageDate, (querySnapshot) => {
    //             newMessages = querySnapshot.docs.map(
    //                 (doc) =>
    //                     ({
    //                         id: doc.id,
    //                         ...doc.data(),
    //                     }) as IMessage,
    //             )
    //             const sortedMessages = [...oldMessages, ...newMessages].sort((a, b) => {
    //                 return a.date > b.date ? 1 : 0;
    //             });
    //             setRawMessages(sortedMessages);
    //         });
    //     })
    //     .catch((err) => {
    //         console.error('Error handling IndexedDB or Firestore:', err);
    //     });

    // return () => {
    //     if (unsub) unsub();
    //     if (indexedDB) {
    //         // 정렬처리 해야함
    //         const messages = [...oldMessages, ...newMessages].sort((a, b) => {
    //             return a.date > b.date ? 1 : 0;
    //         });
    //         saveMessagesToIndexedDB(indexedDB, chatId, messages);
    //         indexedDB.close();
    //     }
    // };


  }, [chatId]);

  useEffect(() => {
    updateReadStatusMessage(chatId, guide.id);
    const scrollEl = messagesRef.current;
    if (!scrollEl) return;

    const images: any = scrollEl.querySelectorAll('.MuiImageList-root li img');
    if (images.length) {
      images[images.length - 1].loading = 'eager';
      images[images.length - 1].addEventListener('load', () => {
        scrollEl.scrollTop = scrollEl.scrollHeight;
      });
    }
    scrollEl.scrollTop = scrollEl.scrollHeight;
  }, [rawMessages]);

  const onClearReply = () => {
    chatDispatch({
      type: 'CLEAR_REPLY',
    });
  };

  return (
      <Box>
        <ChatHeader/>
        <Box
            ref={messagesRef}
            sx={{
              overflowY: 'scroll',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              height: 'calc(100dvh - 181px)',
              backgroundColor: '#F2F2F2',
              px: 2.5,
              py: 2.75,
            }}
        >
          {memoMessages.map((messages, index) => (
              <Messages messages={messages} key={index}/>
          ))}
        </Box>

        <Box
            sx={{
              position: 'sticky',
              left: 0,
              bottom: 0,
            }}
        >
          {chatState.reply.id && (
              <Box
                  sx={{
                    padding: '10px 20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    color: '#444',
                    background: '#fff',
                  }}
              >
                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                >
                  <Typography variant="h6" fontSize="18px" fontWeight="600" color="black">
                    Reply Message
                  </Typography>
                  <CloseIcon onClick={onClearReply}/>
                </Box>
                {chatState.reply.text ?? ''}
              </Box>
          )}
          <MessageForm
              sx={{
                display: 'flex',
                justifyContent: 'between',
                alignItems: 'center',
                py: 1.25,
                width: '100%',
                background: '#fff',
                columnGap: '8px',
                px: 2.5,
              }}
              participants={participants}
          />
        </Box>
      </Box>
  );
}
